<script lang="ts" setup>
import {computed, onMounted, ref} from "vue";
import API from "../API";
import Button from "../components/Button.vue";
import type {ComponentExposed} from "vue-component-type-helpers";
import Field from "../components/Field.vue";
import Textbox from "../components/Textbox.vue";
import Validation from "../components/Validation.vue";
import {useAutosave} from "../components/Autosave";
import {useBreadcrumb} from "../Breadcrumb";
import {useRouter} from "vue-router";

interface Props
{
    projectId: string;
}
const props = withDefaults(defineProps<Props>(), {});

interface Project
{
    name: string;
}
const project = ref<Project | null>(null);

onMounted(async () =>
{
    const {projectId} = props;
    project.value = await API.fetch("get", "/project/{projectId}/", {projectId}, {fields: ["name"]}, null);
});

const getName = () => project.value === null ? "" : project.value.name;
const setName = (value: string) =>
{
    project.value!.name = value;
    autosave();
};
const name = computed({get: getName, set: setName});

const validation = ref<ComponentExposed<typeof Validation>>();

const {autosave, save, saving} = useAutosave("patch", "/project/{projectId}/", async (fetch) =>
{
    if(await validation.value!.validate())
    {
        const {projectId} = props;
        await fetch({projectId}, {}, project.value!);
    }
});
const router = useRouter();
const submit = async () =>
{
    if(await validation.value!.validate(true))
    {
        await save();
        await router.push(`/project/${props.projectId}/`);
    }
};

useBreadcrumb({projectId: () => project.value?.name});
</script>
<template>
    <h1>Define your project</h1>
    <Validation ref="validation">
        <form class="flex flex-col flex-gap-5" v-on:submit.prevent="submit()">
            <Field label="Title of the impact project" rule="NonEmptyString" v-bind:value="name" v-slot="{id}">
                <Textbox v-bind:disabled="project === null" v-bind:id="id" v-model:value="name"/>
            </Field>
            <Button class="flex-self-center" role="primary" type="submit" v-bind:loading="saving">Save</Button>
        </form>
    </Validation>
</template>