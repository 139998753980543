import PageLayout from "../layouts/PageLayout.vue";
import type {RouteRecordRaw} from "vue-router";
import Template from "./Template.vue";
import Templates from "./Templates.vue";
import {id} from "../RouteId";

export const TemplateRoute: RouteRecordRaw =
{
    children:
    [
        {
            component: Templates,
            path: ""
        },
        {
            component: Template,
            meta:
            {
                breadcrumb: (texts) => texts["templateId"] ?? "Template",
                navigation: () => []
            },
            path: `${id("templateId")}/`,
            props: ({params: {templateId}}) => ({templateId})
        }
    ],
    component: PageLayout,
    meta:
    {
        auth: true,
        breadcrumb: () => "Templates"
    },
    path: "/template/"
};