<script lang="ts">
import {DateTime, Interval} from "luxon";
import {computed, ref, watchEffect} from "vue";
import type {DurationObjectUnits} from "luxon";

export function withCalendarAxis(values: {time: DateTime; value: number}[])
{
    const sorted = values.sort(({time: a}, {time: b}) => a.toMillis() - b.toMillis());
    const xs = sorted.map(({time}) => time);
    const min = DateTime.min(...xs);
    const Formats: Partial<Record<keyof DurationObjectUnits, string>> =
    {
        "days": "dd-MM",
        "months": "yyyy-MM",
        "quarters": "yyyy-MM",
        "years": "yyyy"
    };
    type Unit = keyof typeof Formats;
    const units = Object.keys(Formats) as Unit[];
    const durations = xs.flatMap((x, n, xs) =>
    {
        if(n === 0)
        {
            return [];
        }
        else
        {
            const duration = x.diff(xs[n - 1], units).toObject();
            const [unit] = Object.entries(duration).findLast(([_, v]) => v !== 0) as [Unit, number];
            return min === undefined ? [] : [unit];
        }
    });
    const unit = units.find((unit) => durations.includes(unit)) ?? "days";
    const formatter = Formats[unit] ?? "dd-MM";
    const format = (x: number) => min.plus({[unit]: x}).toFormat(formatter);
    const mapped = sorted.map(({time, value}) =>
    {
        const x = Interval.fromDateTimes(min, time).count(unit);
        return {time: x, value};
    });
    return {format, values: mapped};
}
</script>
<script lang="ts" setup>
interface Emits
{
    (event: "track", value: null | {x: number; y: number}): void;
}
interface Props
{
    aspectRatio?: number;
    format?: (x: number) => string;
    labels?: boolean;
    type?: "bar" | "line";
    values?: {time: number; value: number}[];
}
const emit = defineEmits<Emits>();
const props = withDefaults(defineProps<Props>(),
{
    aspectRatio: 2,
    format: (x: number) => x.toString(),
    labels: true,
    type: "bar",
    values: () => []
});
const matrixAspect = computed(() => [props.aspectRatio, 0, 0, 1, 0, 0]);
const matrixOrigin = computed(() =>
{
    const {max, min} = scaleY.value;
    return [1, 0, 0, 1, 0, (0 - min) / (max - min)];
});
const viewBox = computed(() => [0, 0, props.aspectRatio, 1]);

const scaleX = computed(() =>
{
    const xs = props.values.map(({time}) => time);
    const deltas = xs.flatMap((x, n, xs) => n === 0 ? [] : x === xs[n - 1] ? [] : [x - xs[n - 1]]);
    const interval = deltas.length === 0 ? 1 : Math.abs(Math.min(...deltas));
    const max = Math.max(...xs);
    const min = Math.min(...xs);
    const ticks = Math.floor((max - min) / interval) + 1;
    return {interval, max, min, ticks};
});

const scaleY = computed(() =>
{
    const ys = props.values.map(({value}) => value);
    const maxY = Math.max(0, ...ys);
    const minY = Math.min(0, ...ys);
    if(maxY === 0 && minY === 0)
    {
        return {interval: 5, max: 10, min: -10, ticks: 4};
    }
    else
    {
        const magnitude = Math.pow(10, Math.floor(Math.log10(Math.abs(maxY - minY))));
        const magnitudeMax = Math.ceil(maxY / magnitude) * magnitude;
        const magnitudeMin = Math.floor(minY / magnitude) * magnitude;
        const log10 = Math.log10(magnitudeMax - magnitudeMin);
        const scale = Math.pow(10, log10 < Math.log10(5 * magnitude) ? log10 <= Math.log10(2 * magnitude) ? Math.log10(0.2 * magnitude) : Math.log10(0.5 * magnitude) : Math.log10(1 * magnitude));
        const max = Math.ceil(maxY / scale) * scale;
        const min = Math.floor(minY / scale) * scale;
        const ticks = (max - min) / scale;
        const interval = (max - min) / ticks;
        return {interval, max, min, ticks};
    }
});

const gridX = computed(() =>
{
    const {ticks} = scaleX.value;
    return new Array(ticks).fill(0).map((_, n) => (n + 0.5) / ticks);
});

const gridY = computed(() =>
{
    if(props.values.length === 0)
    {
        return [];
    }
    else
    {
        const {ticks} = scaleY.value;
        return new Array(ticks + 1).fill(0).map((_, n) => n / ticks);
    }
});

const formatX = computed(() => props.format);

const formatY = computed(() =>
{
    const {max, min} = scaleY.value;
    const magnitude = Math.max(Math.log10(0 - min), Math.log10(max));
    const decimals = Number.isFinite(magnitude) && magnitude <= 1 ? Math.ceil(1 - magnitude) : 0;
    return (y: number) => Number.isNaN(y) ? "" : y.toFixed(decimals);
});

const labelsX = computed(() =>
{
    const {interval, min, ticks} = scaleX.value;
    const delta = -0.5 / ticks;
    return gridX.value.map((x) => min + (x + delta) * ticks * interval).map((x) => formatX.value(x));
});

const labelsY = computed(() =>
{
    const {interval, min, ticks} = scaleY.value;
    return gridY.value.map((y) => min + y * ticks * interval).map((y) => formatY.value(y));
});

const computeX = computed(() =>
{
    const {interval, max, min} = scaleX.value;
    return (x: number) => (x - min + interval / 2) / (max - min + interval);
});

const inverseX = computed(() =>
{
    const {interval, max, min} = scaleX.value;
    return (x: number) => x * (max - min + interval) + min - interval / 2;
});

const computeY = computed(() =>
{
    const {max, min} = scaleY.value;
    return (y: number) => y / (max - min);
});

const inverseY = computed(() =>
{
    const {max, min} = scaleY.value;
    return (y: number) => y * (max - min);
});

const average = computed(() =>
{
    const {values} = props;
    const sum = values.reduce((sum, {value}) => sum + value, 0);
    return computeY.value(sum / values.length);
});

const bars = computed(() =>
{
    const {ticks} = scaleX.value;
    const width = 0.25 / ticks;
    const bars = props.values.map(({time: x, value}) =>
    {
        const x0 = computeX.value(x) - width / 2;
        const x1 = x0 + width;
        const y0 = computeY.value(0);
        const y1 = computeY.value(value);
        return {x0, x1, y0: Math.min(y0, y1), y1: Math.max(y0, y1)};
    });
    return bars;
});

const catmullRomBezier = (data: number[][], alpha: number) =>
{
    const d: number[][][] = new Array(data.length - 1).fill([]);
    const length = data.length;
    for(let i = 0; i < length - 1; i++)
    {
        const p0: number[] = i === 0 ? data[0] : data[i - 1];
        const p1: number[] = data[i];
        const p2: number[] = data[i + 1];
        const p3: number[] = i + 2 < length ? data[i + 2] : p2;

        const d1 = Math.sqrt(Math.pow(p0[0] - p1[0], 2) + Math.pow(p0[1] - p1[1], 2));
        const d2 = Math.sqrt(Math.pow(p1[0] - p2[0], 2) + Math.pow(p1[1] - p2[1], 2));
        const d3 = Math.sqrt(Math.pow(p2[0] - p3[0], 2) + Math.pow(p2[1] - p3[1], 2));

        const d1pow1A = Math.pow(d1, alpha);
        const d1pow2A = Math.pow(d1, 2 * alpha);
        const d2pow1A = Math.pow(d2, alpha);
        const d2pow2A = Math.pow(d2, 2 * alpha);
        const d3pow1A = Math.pow(d3, alpha);
        const d3pow2A = Math.pow(d3, 2 * alpha);

        const A = 2 * d1pow2A + 3 * d1pow1A * d2pow1A + d2pow2A;
        const B = 2 * d3pow2A + 3 * d3pow1A * d2pow1A + d2pow2A;
        let N = 3 * d1pow1A * (d1pow1A + d2pow1A);
        let M = 3 * d3pow1A * (d3pow1A + d2pow1A);
        if(N > 0)
        {
            N = 1.0 / N;
        }
        if(M > 0)
        {
            M = 1.0 / M;
        }
        let bp1 =
        [
            (-d2pow2A * p0[0] + A * p1[0] + d1pow2A * p2[0]) * N,
            (-d2pow2A * p0[1] + A * p1[1] + d1pow2A * p2[1]) * N
        ];
        let bp2 =
        [
            (d3pow2A * p1[0] + B * p2[0] - d2pow2A * p3[0]) * M,
            (d3pow2A * p1[1] + B * p2[1] - d2pow2A * p3[1]) * M
        ];
        if(bp1[0] === 0.0 && bp1[1] === 0.0)
        {
            bp1 = p1;
        }
        if(bp2[0] === 0.0 && bp2[1] === 0.0)
        {
            bp2 = p2;
        }
        d[i] = [bp1, bp2, p2];
    }
    return d;
};

const dots = computed(() =>
{
    const dots = props.values.map(({time, value}) =>
    {
        const x = computeX.value(time);
        const y = computeY.value(value);
        return {x, y};
    });
    return dots;
});

const segments = computed(() =>
{
    const curves = catmullRomBezier(dots.value.map(({x, y}) => [x, y]), 0.5);
    return curves;
});

const curve = computed(() =>
{
    const curve = segments.value.map((ps, n) => ps.map(([x, y], p) => p === 0 ? n === 0 ? `M${x} ${y}C${x} ${y}` : `C${x} ${y}` : `${x} ${y}`).join(" ")).join("");
    return curve;
});

const paths = computed(() =>
{
    const {interval} = scaleX.value;
    const gaps = props.values.flatMap(({time: t0}, n, values) =>
    {
        if(n < values.length - 1)
        {
            const {time: t1} = values[n + 1];
            const dt = t1 - t0;
            return Math.abs(dt - interval) > Number.EPSILON ? [n] : [];
        }
        else
        {
            return [];
        }
    });
    const paths = segments.value.map((ps, n, curves) => ({gap: gaps.includes(n), path: ps.map(([x, y], p) => p === 0 ? n === 0 ? `M${x} ${y}C${x} ${y}` : `M${curves[n - 1][2][0]} ${curves[n - 1][2][1]}C${x} ${y}` : `${x} ${y}`).join(" ")}));
    return paths;
});

const g = ref<SVGGElement | null>(null);
const path = ref<SVGPathElement | null>(null);
const track = ref<{x: number, y: number} | null>(null);

const cancelTracker = () =>
{
    track.value = null;
};

const discreteTracker = (e: MouseEvent) =>
{
    const pt = new DOMPoint();
    pt.x = e.clientX;
    pt.y = e.clientY;
    const matrix = g.value!.getScreenCTM()!;
    if(matrix.a === 0 || matrix.d === 0)
    {
        track.value = null;
    }
    else
    {
        const {x: px} = pt.matrixTransform(matrix.inverse());
        const {x, y} = dots.value.reduce((a, b) => Math.abs(a.x - px) < Math.abs(b.x - px) ? a : b);
        track.value = {x, y};
    }
};

watchEffect(() =>
{
    const xy = track.value;
    if(xy === null)
    {
        if(props.values.length === 0)
        {
            emit("track", null);
        }
        else
        {
            const {time, value} = props.values[props.values.length - 1];
            emit("track", {x: time, y: value});
        }
    }
    else
    {
        const {x, y} = xy;
        emit("track", {x: inverseX.value(x), y: inverseY.value(y)});
    }
});
</script>
<style>
@supports (-moz-transition:none)
{
    .no-moz-transition,
    .no-moz-transition *
    {
        transition: none;
        transform: scale(100%);
    }
}
</style>
<template>
    <div class="grid" style="grid-template-columns: min-content 1fr; grid-template-rows: 1fr min-content" v-bind:class="values.length === 0 ? 'bg-gray/5' : 'bg-white'">
        <svg class="color-middlegray grid-col-2 grid-row-1" v-bind:viewBox="viewBox.join(' ')" xmlns="http://www.w3.org/2000/svg" version="1.1">
            <g transform="matrix(1 0 0 -1 0 1)">
                <line stroke="currentColor" vector-effect="non-scaling-stroke" x1="0" v-bind:key="index" v-bind:x2="aspectRatio" v-bind:y1="y" v-bind:y2="y" v-bind:stroke-width="index === 0 || index === gridY.length - 1 ? 1 : 0.5" v-for="(y, index) of gridY"/>
            </g>
        </svg>
        <div class="flex flex-items-center flex-justify-center grid-col-2 grid-row-1" v-if="values.length === 0">
            <div class="color-gray/50">No data.</div>
        </div>
        <template v-else>
            <svg class="color-green grid-col-2 grid-row-1" v-bind:viewBox="viewBox.join(' ')" xmlns="http://www.w3.org/2000/svg" version="1.1" v-on:mousemove="discreteTracker" v-on:mouseleave="cancelTracker">
                <g transform="matrix(1 0 0 -1 0 1)">
                    <g v-bind:transform="`matrix(${matrixAspect}) matrix(${matrixOrigin})`" v-if="type === 'bar'">
                        <Transition appear appearActiveClass="no-moz-transition transition-duration-2s transition-transform" appearFromClass="transform-scale-y-0" appearToClass="transform-scale-y-100">
                            <g ref="g">
                                <line stroke="currentColor" stroke-dasharray="6 6" stroke-width="1" vector-effect="non-scaling-stroke" x1="0" x2="1" v-bind:y1="average" v-bind:y2="average" v-if="average && labels"/>
                                <rect fill="currentColor" v-bind:key="index" v-bind:height="y1 - y0" v-bind:width="x1 - x0" v-bind:x="x0" v-bind:y="y0" v-for="({x0, y0, x1, y1}, index) of bars"/>
                                <line stroke="currentColor" stroke-width="1" vector-effect="non-scaling-stroke" x1="0" x2="1" v-bind:y1="track.y" v-bind:y2="track.y" v-if="labels && track"/>
                                <line stroke="currentColor" stroke-width="1" vector-effect="non-scaling-stroke" y1="-1" y2="1" v-bind:x1="track.x" v-bind:x2="track.x" v-if="labels && track"/>
                            </g>
                        </Transition>
                    </g>
                    <g v-if="type === 'line'">
                        <g v-bind:transform="`matrix(${matrixOrigin})`">
                            <Transition appear appearActiveClass="no-moz-transition transition-duration-5s transition-transform" appearFromClass="transform-scale-y-0" appearToClass="transform-scale-y-100">
                                <g>
                                    <g ref="g" v-bind:transform="`matrix(${matrixAspect})`">
                                        <line stroke="currentColor" stroke-dasharray="6 6" stroke-width="1" vector-effect="non-scaling-stroke" x1="0" x2="1" v-bind:y1="average" v-bind:y2="average" v-if="average"/>
                                        <path fill="none" stroke-width="2" vector-effect="non-scaling-stroke" v-bind:d="path" v-bind:key="index" v-bind:stroke="gap ? 'gray' : 'currentColor'" v-bind:stroke-dasharray="gap ? '6' : '0'" v-for="({gap, path}, index) of paths"/>
                                        <path fill="none" ref="path" stroke="transparent" stroke-width="2" vector-effect="non-scaling-stroke" v-bind:d="curve"/>
                                        <line stroke="currentColor" stroke-width="1" vector-effect="non-scaling-stroke" x1="0" x2="1" v-bind:y1="track.y" v-bind:y2="track.y" v-if="track"/>
                                        <line stroke="currentColor" stroke-width="1" vector-effect="non-scaling-stroke" y1="-1" y2="1" v-bind:x1="track.x" v-bind:x2="track.x" v-if="track"/>
                                    </g>
                                    <g>
                                        <circle fill="#fff" stroke="currentColor" v-bind:cx="x * aspectRatio" v-bind:cy="y" v-bind:key="index" v-bind:r="0.005 * aspectRatio" v-bind:stroke-width="0.0025 * aspectRatio" v-for="({x, y}, index) of dots"/>
                                    </g>
                                </g>
                            </Transition>
                        </g>
                    </g>
                </g>
            </svg>
            <div class="color-middlegray grid-col-1 grid-row-1 m-r-2 relative" v-if="labels">
                <div class="h-100%" style="container: grid / size">
                    <div class="absolute bg-white p-2 text-right translate-y--50% overflow-y-hidden w-100%" v-bind:style="{top: `${100 - y * 100}%`, zIndex: 5 - 5 % n}" v-for="(y, n) of gridY">{{labelsY[n]}}</div>
                </div>
                <div class="h-0 invisible" v-for="(_, n) of gridY">{{labelsY[n]}}</div>
            </div>
            <div class="color-middlegray grid-col-2 grid-row-2 p-t-1 relative" v-if="labels">
                <div class="absolute bg-white p-2 text-nowrap translate-x--50%" v-bind:style="{left: `${x * 100}%`, zIndex: 5 - n % 5}" v-for="(x, n) of gridX">{{labelsX[n]}}</div>
                <div class="inline-block invisible p-2 text-nowrap" v-for="(_, n) of gridX">{{labelsX[n]}}</div>
            </div>
        </template>
    </div>
</template>