export type AbortablePromise<T> = Promise<T> & {abort: () => void};

/**
 * Error that is thrown when an abortable promise is aborted.
 */
export class AbortError extends Error
{
    constructor()
    {
        super("AbortError");
    }
}

/**
 * Decorates a promise such that it can use an abort function.
 *
 * @param promise the promise to decorate.
 * @param abort the function to call to abort the promise.
 * @returns the abortable promise.
 * @example
 * ```ts
 * function abortableTimeout(timeout: number): AbortablePromise<void>
 * {
 *   let timer: NodeJS.Timeout;
 *   const promise = new Promise<void>((resolve) => timer = setTimeout(resolve, timeout));
 *   return abortablePromise(promise, () => clearTimeout(timer));
 * }
 * ```
 */
export function abortablePromise<T>(promise: Promise<T>, abort: () => void): AbortablePromise<T>
{
    const aborter = new AbortController();
    const aborted = new Promise<T>((_, reject) =>
    {
        const {signal} = aborter;
        signal.addEventListener("abort", () =>
        {
            abort();
            reject(new AbortError());
        });
    });
    const abortable = new Promise<T>((resolve, reject) => Promise.race([aborted, promise]).then(resolve).catch((e) => reject(e)));
    return Object.assign(abortable, {abort: () => aborter.abort()});
}

/**
 * Decorates an abortable function such that it is never invoked concurrently.
 *
 * @param f the abortable function to decorate.
 * @returns the function that is never invoked concurrently.
 */
export function serialInvocation<A extends any[], R>(f: (...args: A) => AbortablePromise<R>): (...args: A) => Promise<R | AbortError>
{
    let promise: AbortablePromise<R> | null = null;
    return async (...args: A) =>
    {
        if(promise !== null)
        {
            promise.abort();
            await promise;
        }
        promise = f(...args);
        return await promise;
    };
}