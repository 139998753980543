<script lang="ts" setup>
import Toolbar, {type ToolbarButton} from "../components/Toolbar.vue";
import {awaitable, awaitableSelection} from "../components/Awaitable";
import {computed, onMounted, ref} from "vue";
import API from "../API";
import Checkbox from "../components/Checkbox.vue";
import ModalQuestion from "../components/ModalQuestion.vue";
import Table from "../components/Table.vue";
import {invokable} from "../components/Invokable";
import {useBreadcrumb} from "../Breadcrumb";
import {useRouter} from "vue-router";

interface Props
{
}
withDefaults(defineProps<Props>(), {});

interface Template
{
    id: string;
    name?: string;
}
const templates = ref<Template[] | null>(null);

onMounted(async () =>
{
    templates.value = await API.fetch("get", "/template/", {}, {fields: ["name"]}, null);
});

const router = useRouter();

const adding = ref(false);
const addTemplate = awaitable(adding, async () =>
{
    const templateId = await API.fetch("post", "/template/", {}, {}, null);
    router.push(`/template/${templateId}/`);
});

const gotoEdit = (templateId: string) =>
{
    router.push(`/template/${templateId}/`);
};

const selected = ref<string[]>([]);
const select = (checked: boolean, templateId: string) =>
{
    if(checked)
    {
        selected.value.push(templateId);
    }
    else
    {
        selected.value = selected.value.filter((id) => id !== templateId);
    }
};

const removing = ref(new Set<string>());
const remove = ref<(() => Promise<void>) | null>(null);
const removeTemplates = invokable(remove, awaitableSelection(removing, async (templateIds: string[]) =>
{
    const promises = templateIds.map((templateId) => API.fetch("delete", "/template/{templateId}/", {templateId: templateId}, {}, null));
    await Promise.all(promises);
    templates.value = templates.value!.filter(({id}) => templateIds.includes(id) === false);
    selected.value = selected.value.filter((id) => templateIds.includes(id) === false);
}));

const toolbar = computed(() =>
{
    const buttons: ToolbarButton[] =
    [
        {
            action: addTemplate,
            icon: "i-material-symbols:add",
            label: "Add template"
        },
        {
            action: () => removeTemplates([...selected.value]),
            disabled: selected.value.length === 0,
            icon: "i-material-symbols:delete-outline-rounded",
            label: "Remove templates",
            wait: removing.value.size > 0
        }
    ];
    return buttons;
});

useBreadcrumb();
</script>
<template>
    <h1>Templates</h1>
    <Toolbar class="m-t-6" v-bind:buttons="toolbar"/>
    <Table class="m-t-3" collapse-first collapse-last v-bind:columns="2" v-bind:rows="templates">
        <template v-slot:header:1>Name</template>
        <template v-slot:column:1="{row: {id, name}}">
            <Checkbox v-bind:disabled="removing.has(id)" v-bind:value="selected.includes(id)" v-on:update:value="(checked) => select(checked, id)">
                <span>{{name}}</span>
            </Checkbox>
        </template>
        <template v-slot:column:2="{row: {id}}">
            <div class="flex flex-gap-2 flex-justify-center flex-items-center flex-grow flex-row">
                <a aria-busy="true" class="color-green i-svg-spinners:180-ring-with-bg text-6" v-if="removing.has(id)"/>
                <a aria-label="Edit" class="i-material-symbols:edit-outline-rounded link text-6" v-on:click="gotoEdit(id)" v-else/>
            </div>
        </template>
        <template v-slot:placeholder v-if="templates?.length === 0">
            <div class="flex flex-justify-center">
                <div class="color-lightgray">No template templates created yet</div>
            </div>
        </template>
    </Table>
    <ModalQuestion v-bind:visible="remove !== null" v-on:answer:yes="remove!()" v-on:update:visible="remove = null">
        <template v-slot:title>Are you sure you want to delete {{selected.length}} {{selected.length === 1 ? "template" : "templates"}}?</template>
        <template v-slot:text>Once deleted, you won’t be able to recover {{selected.length === 1 ? "it" : "them"}}.</template>
        <template v-slot:yes>Yes, delete {{selected.length === 1 ? "it" : "them"}}</template>
        <template v-slot:no>No, keep {{selected.length === 1 ? "it" : "them"}}</template>
    </ModalQuestion>
</template>../components/Toolbar.vue