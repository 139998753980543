/**
 * Decorates the function such that any currently running call is aborted.
 *
 * @param fn the decorated function.
 * @returns the abortable function.
 */
export function abortable<A extends any[], P>(fn: (abort: AbortController, ...args: A) => Promise<P>)
{
    const {abortable} = abortableWithAbort(fn);
    return abortable;
}

/**
 * Decorates the function such that any currently running call is aborted.
 *
 * @param fn the decorated function.
 * @returns the abort and abortable functions.
 */
export function abortableWithAbort<A extends any[], P>(fn: (abort: AbortController, ...args: A) => Promise<P>)
{
    let controller: AbortController | null = null;
    const abort = () =>
    {
        if(controller !== null)
        {
            controller.abort();
        }
    };
    const abortable = async (...args: A) =>
    {
        if(controller !== null)
        {
            controller.abort();
        }
        controller = new AbortController();
        return await run(fn, controller, ...args);
    };
    return {abort, abortable};
}

async function run<A extends any[], P>(fn: (abort: AbortController, ...args: A) => Promise<P>, abort: AbortController, ...args: A)
{
    try
    {
        return await fn(abort, ...args);
    }
    catch(e)
    {
        if(abort.signal.aborted === false)
        {
            throw e;
        }
    }
}