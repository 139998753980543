<script lang="ts" setup>
import {ref, watch} from "vue";
import Button from "./Button.vue";
import type {ComponentExposed} from "vue-component-type-helpers";
import Modal from "./Modal.vue";

interface Emits
{
    (event: "answer:yes"): void;
    (event: "answer:no"): void;
    (event: "update:visible", value: boolean): void;
}
interface Props
{
    visible?: boolean;
}
const emit = defineEmits<Emits>();
const props = withDefaults(defineProps<Props>(), {visible: false});
const modal = ref<ComponentExposed<typeof Modal>>();
const no = () =>
{
    emit("answer:no");
    modal.value!.close();
};
const yes = () =>
{
    emit("answer:yes");
    modal.value!.close();
};
const shortcut = (event: KeyboardEvent) =>
{
    if(event.key === "Escape")
    {
        no();
    }
};
watch(() => props.visible, (visible) =>
{
    if(visible)
    {
        window.addEventListener("keydown", shortcut);
    }
    else
    {
        window.removeEventListener("keydown", shortcut);
    }
});
</script>
<template>
    <Modal v-bind:visible="visible" v-on:update:visible="(visible) => emit('update:visible', visible)" ref="modal">
        <h2>
            <slot name="title"/>
        </h2>
        <p>
            <slot name="text"/>
        </p>
        <div class="flex flex-gap-3 flex-items-center flex-justify-around flex-wrap">
            <Button role="primary" type="button" v-on:click="yes()">
                <slot name="yes"/>
            </Button>
            <Button role="secondary" type="button" v-on:click="no()">
                <slot name="no"/>
            </Button>
        </div>
    </Modal>
</template>