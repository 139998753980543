<script lang="ts" setup>
import {computed, ref, watchEffect} from "vue";

interface Emits
{
    (event: "track", value: null | {name: string; value: number}): void;
}
interface Props
{
    labels?: boolean;
    values?: {name: string; value: number}[];
}
const emit = defineEmits<Emits>();
const props = withDefaults(defineProps<Props>(),
{
    labels: true,
    values: () => []
});

const texts = computed(() => props.values.map(({name}) => name));
const values = computed(() => props.values.map(({value}) => value));
const total = computed(() => values.value.reduce((a, b) => a + b, 0));

const sums = computed(() => values.value.map((v) => v / total.value).map((_, n, a) => a.slice(0, n + 1).reduce((a, b) => a + b, 0)));
const arcs = computed(() => sums.value.length === 1 ? ["M -1 0 A 1 1 0 0 0 1 0 A 1 1 0 0 0 -1 0 Z"] : sums.value.map((v, n, a) => `M ${Math.sin(n === 0 ? 0 : a[n - 1] * Math.PI * 2)} ${Math.cos(n === 0 ? 0 : a[n - 1] * Math.PI * 2)} A 1 1 0 ${n === 0 ? 0 : v - a[n - 1] <= 0.5 ? 0 : 1} 0 ${Math.sin(v * Math.PI * 2)} ${Math.cos(v * Math.PI * 2)} L 0 0 Z`));
const rots = computed(() => sums.value.map((v, n, a) => n === 0 ? v * Math.PI : (a[n - 1] + v) * Math.PI));

const colors = computed(() => values.value.map((_, n) => `lch(50% 77 ${n * 360 / values.value.length})`));
const tracked = ref<number | null>(null);
watchEffect(() => emit("track", tracked.value === null ? null : props.values[tracked.value]));
</script>
<style>
@supports (-moz-transition:none)
{
    .no-moz-transition,
    .no-moz-transition *
    {
        transition: none;
        transform: scale(100%);
    }
}
</style>
<template>
    <div class="flex flex-items-center flex-justify-center m-b-[calc(1lh+1rem)] m-t-[calc(1lh+1rem)] w-100%" style="aspect-ratio: 4">
        <div class="flex flex-items-center flex-justify-center h-100% w-100%" style="container: pie / size">
            <svg class="color-gray/10 h-100% w-100%" viewBox="-1 -1 2 2" xmlns="http://www.w3.org/2000/svg" version="1.1">
                <g transform="matrix(0.95 0 0 -0.95 0 0)" v-bind:style="{'--total': arcs.length}" >
                    <circle cx="0" cy="0" r="1" fill="currentColor"/>
                    <TransitionGroup appear appearActiveClass="no-moz-transition transition-duration-[calc(1s/var(--total))] transition-delay-[calc((var(--index)+1)*1s/var(--total))] transition-transform" appearFromClass="transform-scale-0" appearToClass="transform-scale-100">
                        <g v-bind:key="index" v-bind:style="{'--index': index}" v-for="(arc, index) of arcs">
                            <path class="cursor-pointer transition-transform" v-bind:class="{'transform-scale-105': tracked === index}" stroke="white" stroke-width="1" vector-effect="non-scaling-stroke" v-bind:d="arc" v-bind:fill="colors[index]" v-on:mouseenter="tracked = index" v-on:mouseleave="tracked = null"/>
                        </g>
                    </TransitionGroup>
                </g>
            </svg>
            <div class="absolute flex flex-items-center flex-justify-center h-100% w-100%" v-if="values.length === 0">
                <div class="color-gray/50">No data.</div>
            </div>
            <template v-if="labels">
                <div class="absolute b-1 b-green w-100cqmin" v-bind:style="`transform:rotate(${Math.PI * 3/2 + rots[index]}rad) translate(100%)`" v-for="(label, index) of texts" v-bind:key="index">
                    <div class="absolute b-1 b-red box-border pointer-events-none translate-x--50% translate-y--50%">
                        <div class="break-words box-border b-1 b-blue cursor-pointer max-h-[calc(1lh+1rem)] max-w-37.5cqw overflow-hidden p-2 pointer-events-auto text-center text-ellipsis text-nowrap" v-bind:class="{'color-green': tracked === index}" v-bind:style="`transform:rotate(calc(-${rots[index]}rad + 90deg)) translate(calc(sin(${rots[index]}) * 50%), calc(cos(${rots[index]}) * -50%))`" v-on:mouseenter="tracked = index" v-on:mouseleave="tracked = null">{{label}}</div>
                        <div class="absolute b-1 b-solid b-gray-4 w-2 left-[calc(50%-0.5rem)] top-50%"/>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>
