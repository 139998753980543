<script lang="ts" setup>
import BasicCard from "./BasicCard.vue";
import {useSlots} from "vue";

interface Props
{
    loading?: boolean;
}

withDefaults(defineProps<Props>(), {loading: false});
const slots = useSlots();
</script>
<template>
    <BasicCard v-bind:loading="loading">
        <template v-slot:header v-if="slots.header">
            <slot name="header"/>
        </template>
        <template v-slot:default>
            <div class="col-gap-3 flex flex-items-center grid-justify-self-stretch">
                <div class="color-green flex flex-items-center text-6">
                    <slot name="icon"/>
                </div>
                <div class="color-black">
                    <slot/>
                </div>
            </div>
        </template>
    </BasicCard>
</template>