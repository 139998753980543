<script lang="ts">
import {type RouteLocation, useRoute, useRouter} from "vue-router";
import {computed, onMounted, ref} from "vue";
import API from "../API";
import CardsLayout from "../components/cards/CardsLayout.vue";
import type {Navigation} from "../Navigation";
import NavigationCard from "../components/cards/NavigationCard.vue";
import {useBreadcrumb} from "../Breadcrumb";

export const navigation = (route: RouteLocation) =>
{
    const {projectId} = route.params;
    const navigation: Array<Navigation & {description: string}> =
    [
        {
            description: "Enter details of your impact project.",
            href: `/project/${projectId}/define/`,
            icon: "i-material-symbols:folder-outline",
            text: "Define project"
        },
        {
            description: "GHG assistant helps you to calculate your project's carbon footprint.",
            href: `/project/${projectId}/assistant/`,
            icon: "i-mdi:google-earth",
            text: "GHG Assistant"
        },
        {
            description: "Use logic model to outline how desired impacts will happen.",
            href: `/project/${projectId}/toc/`,
            icon: "i-material-symbols:magic-exchange",
            text: "Theory of Change"
        },
        {
            description: "Add self-defined or standardized indicators to measure impact.",
            href: `/project/${projectId}/indicator/`,
            icon: "i-mdi:database-edit-outline",
            text: "Manage Indicators"
        },
        {
            description: "Get comprehensive insights of your impact.",
            href: `/project/${projectId}/analyze/`,
            icon: "i-material-symbols:bar-chart-4-bars-rounded",
            text: "Analyze impact"
        },
        {
            description: "Create impact reports using qualitative and quantitative data.",
            href: `/project/${projectId}/reports/`,
            icon: "i-mdi:file-document-outline",
            text: "Create reports"
        }
    ];
    return navigation;
};
</script>
<script lang="ts" setup>
interface Props
{
    projectId: string;
}
const props = withDefaults(defineProps<Props>(), {});
const project = ref<{name: string} | null>();
onMounted(async () =>
{
    const {projectId} = props;
    project.value = await API.fetch("get", "/project/{projectId}/", {projectId}, {fields: ["name"]}, null);
});

const route = useRoute();
const router = useRouter();
const cards = computed(() => navigation(route));
useBreadcrumb({projectId: () => project.value?.name});
</script>
<template>
    <h1>{{project?.name ?? "&nbsp;"}}</h1>
    <p>Follow the flow below to measure, analyze and report impact.</p>
    <CardsLayout>
        <NavigationCard v-bind:arrow="index < navigation.length - 1" v-bind:key="index" v-on:click="router.push(href)" v-for="({description, href, text}, index) of cards">
            <template v-slot:title>{{text}}</template>
            <template v-slot:description>{{description}}</template>
        </NavigationCard>
    </CardsLayout>
</template>
