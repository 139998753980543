import {GOOGLE_RECAPTCHA_SITE_KEY} from "./Environment";

const recaptcha = new Promise<typeof grecaptcha.enterprise>((resolve) =>
{
    const script = document.createElement("script");
    script.addEventListener("load", () => grecaptcha.enterprise.ready(() =>
    {
        document.querySelector("#grecaptcha")!.appendChild(document.querySelector(".grecaptcha-badge")!);
        resolve(grecaptcha.enterprise);
    }));
    script.setAttribute("defer", "");
    script.setAttribute("src", `https://www.google.com/recaptcha/enterprise.js?render=${GOOGLE_RECAPTCHA_SITE_KEY}`);
    document.body.appendChild(script);
});

/**
 * Captcha service.
 */
export default class Captcha
{
    /**
     * Gets the captcha token.
     *
     * @param {String} action the user action.
     * @returns the captcha token.
     */
    static async captcha(action: string)
    {
        const captcha = await recaptcha;
        return await captcha.execute(GOOGLE_RECAPTCHA_SITE_KEY, {action});
    }
}