<script lang="ts" setup>
import Textbox from "./Textbox.vue";
import {computed} from "vue";
import {useDecimal} from "./Decimal";

interface Emits
{
    (event: "update:value", value: number): void;
}
interface Props
{
    value: number;
}
const emit = defineEmits<Emits>();
const props = withDefaults(defineProps<Props>(), {});
const get = () => props.value;
const set = (value: number) => emit("update:value", value);
const decimal = useDecimal(computed({get, set}));
</script>
<template>
    <Textbox v-model:value="decimal"/>
</template>