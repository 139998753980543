import type {Session} from "./API";
import {decodeJwt} from "jose";
import {ref} from "vue";

/**
 * Gets the authorization cookie payload, if any.
 */
export default class Authorization
{
    static decode(): null | Pick<Session, "roles" | "sub" | "subscription" | "trial">
    {
        const jwt = Authorization.get();
        if(jwt === null)
        {
            return null;
        }
        else
        {
            const {exp, roles, sub, subscription, trial} = decodeJwt<Session>(jwt);
            const expires = Date.now() / 1000;
            if(exp < expires)
            {
                return null;
            }
            else
            {
                return {roles, sub, subscription, trial};
            }
        }
    }

    static get()
    {
        return localStorage.getItem("Authorization");
    }

    static remove()
    {
        localStorage.removeItem("Authorization");
        authorization.value = null;
    }

    static set(jwt: string)
    {
        localStorage.setItem("Authorization", jwt);
        authorization.value = Authorization.decode();
    }
}

export const authorization = ref(Authorization.decode());