import {type MaybeRefOrGetter, onMounted, ref, toValue, watchPostEffect} from "vue";
import {type RouteLocation, useRoute} from "vue-router";
import API from "./API";

function fetchProjectBreadcrumb(params: Record<string, string>)
{
    const {projectId} = params;
    const project = ref<{name: string} | null>(null);
    onMounted(async () => project.value = await API.fetch("get", "/project/{projectId}/", {projectId}, {fields: ["name"]}, null));
    return () => project.value?.name;
}

const fetchers: Partial<Record<string, (params: Record<string, string>) => MaybeRefOrGetter<string | undefined>>> =
{
    projectId: fetchProjectBreadcrumb
};

interface Breadcrumb
{
    disabled: boolean;
    href: string;
    text: string;
}

export const breadcrumb = ref<Breadcrumb[]>([]);

export function useBreadcrumb(texts: Record<string, MaybeRefOrGetter<string | undefined>> = {})
{
    const route = useRoute();
    const {matched, params} = route;
    const match = matched[matched.length - 1].path.matchAll(/:(?:([^(/]+)[^/]*)/g);
    const parts = Array.of(...match).map(([, name]) => name);
    const breadcrumbs: [string, MaybeRefOrGetter<string | undefined>][] = parts.map((name) => [name, texts[name] ?? fetchers[name]?.(params as Record<string, string>)]);
    watchPostEffect(() => useRouteBreadcrumb(route, Object.fromEntries(breadcrumbs.map(([param, text]) => [param, toValue(text)]))));
}

export function useRouteBreadcrumb({matched, params}: RouteLocation, texts: Record<string, string | undefined>)
{
    breadcrumb.value = matched.flatMap((record) =>
    {
        const {meta: {breadcrumb: breadcrumb}, path} = record;
        if(breadcrumb instanceof Function)
        {
            const href = path.replaceAll(/:(?:([^(/]+)[^/]*)/g, (_, name) => params[name].toString());
            const link =
            {
                disabled: false,
                href: href,
                text: breadcrumb(texts)
            };
            return [link];
        }
        else
        {
            return [];
        }
    });
}