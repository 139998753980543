import Authorization from "../Authorization";
import ModalLayout from "../layouts/ModalLayout.vue";
import type {RouteRecordRaw} from "vue-router";
import SignIn from "./SignIn.vue";

export const AuthRoutes: RouteRecordRaw[] =
[
    {
        children:
        [
            {
                component: SignIn,
                path: "",
                props: ({query: {code, state}}) => ({code, state})
            }
        ],
        component: ModalLayout,
        path: "/linkedin/"
    },
    {
        children:
        [
            {
                component: SignIn,
                path: "",
                props: ({query: {redirect}}) => ({redirect})
            },
            {
                component: SignIn,
                path: "email/:token/",
                props: ({params: {token}}) => ({token})
            }
        ],
        component: ModalLayout,
        path: "/signin/"
    },
    {
        beforeEnter(to, {path})
        {
            Authorization.remove();
            return `/signin/?redirect=${encodeURIComponent(path)}`;
        },
        component: {},
        path: "/signout/"
    }
];