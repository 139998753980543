<script lang="ts" setup>
import {ref} from "vue";

interface Emits
{
    (e: "update:working", working: boolean): void
    (e: "upload", buffer: ArrayBuffer, filename: string, type: string): void
}
interface Props
{
    accept: string;
}
const emit = defineEmits<Emits>();
withDefaults(defineProps<Props>(), {accept: "*/*"});

const input = ref<HTMLInputElement>();
const select = () => input.value!.click();
const upload = async () =>
{
    const {files} = input.value!;
    if(files !== null && files.length === 1)
    {
        const [file] = files;
        const {name, type} = file;
        const reader = new FileReader();
        const buffer = await new Promise<ArrayBuffer>((resolve, reject) =>
        {
            reader.addEventListener("load", () => resolve(reader.result as ArrayBuffer));
            reader.addEventListener("error", () => reject(reader.error));
            reader.readAsArrayBuffer(file);
        });
        emit("upload", buffer, name, type);
    }
};
</script>
<template>
    <slot v-bind="{select}"/>
    <input class="hidden" ref="input" type="file" v-bind:accept="accept" v-on:change="upload" v-on:click="(e) => e.target!.value = ''"/>
</template>