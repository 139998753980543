<script lang="ts" setup>
interface Emits
{
    (event: "update:value", value: string): void;
}
interface Props
{
    disabled?: boolean;
    value?: string;
}
const emit = defineEmits<Emits>();
withDefaults(defineProps<Props>(),
{
    disabled: false,
    value: ""
});
const update = (value: string) => emit("update:value", value);
</script>
<template>
    <div>
        <div class="box-border relative h-100% p-3 p-l-9 w-100%" style="word-break: break-word" v-bind:class="[disabled ? 'color-middlegray' : '']">
            <ul class="box-border font-sans m-0 p-0 text-4 w-100% white-space-pre-wrap">
                <li v-for="line of value.split('\n')">
                    <span class="text-transparent" style="white-space: pre-wrap">{{line}}&ZeroWidthSpace;</span>
                </li>
            </ul>
            <textarea class="absolute b-none bg-transparent box-border font-sans h-100% left-0 min-h-1lh p-3 p-l-9 outline-none resize-none text-4 top-0 w-100%" v-bind:disabled="disabled" v-bind:value="value" v-on:input="update(($event.target as HTMLInputElement).value)" />
        </div>
    </div>
</template>