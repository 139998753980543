<script lang="ts" setup>
import {onMounted, ref} from "vue";
import API from "../API";
import {ComponentExposed} from "vue-component-type-helpers";
import Field from "../components/Field.vue";
import GoogleButton from "./GoogleButton.vue";
import LinkedInButton from "./LinkedInButton.vue";
import Textbox from "../components/Textbox.vue";
import Validation from "../components/Validation.vue";
import {awaitable} from "../components/Awaitable";
import {useRouter} from "vue-router";

interface Props
{
    code?: string;
    redirect?: string;
    state?: string;
    token?: string;
}
const props = withDefaults(defineProps<Props>(), {redirect: "/"});
const email = ref("");
const sent = ref(false);
const wait = ref(false);

const validation = ref<ComponentExposed<typeof Validation>>();
const submit = awaitable(wait, async() =>
{
    if(validation.value!.validate())
    {
        const {redirect} = props;
        const form =
        {
            email: email.value,
            redirect
        };
        await API.fetch("post", "/signin/email/", {}, {}, form);
        sent.value = true;
    }
});

const router = useRouter();
const login = awaitable(wait, async() =>
{
    const {code, state, token} = props;
    if(code !== undefined && state !== undefined)
    {
        const redirect = await API.fetch("post", "/signin/linkedin/", {}, {code, state}, null);
        await router.replace(redirect ?? "/");
    }
    else if(token !== undefined)
    {
        const redirect = await API.fetch("get", "/signin/email/", {}, {token}, null);
        await router.replace(redirect ?? "/");
    }
});
onMounted(() => login());
</script>
<template>
    <div class="relative" v-bind:aria-busy="wait" >
        <Validation ref="validation">
            <h2>Sign In</h2>
            <div class="color-gray m-b-4">Jump-start your impact measurement journey.</div>
            <div class="grid grid-gap-4 grid-items-start grid-items-top grid-justify-items-center grid-cols-1 grid-rows-1 sm-grid-cols-1-1">
                <form class="grid grid-gap-4 grid-justify-stretch grid-justify-self-stretch grid-rows-1 sm-grid-col-1-3 sm-grid-cols-1-1" novalidate v-on:submit.prevent="submit">
                    <Field id="email" label="Email" rule="Email" v-bind:value="email">
                        <Textbox autocomplete="email" id="email" name="email" type="email" v-model:value="email"/>
                    </Field>
                    <button class="button grid grid-cols-1-2-1 grid-items-center grid-justify-center grid-justify-self-center grid-self-start m-t-2" type="submit" v-bind:disabled="wait">
                        <span class="i-svg-spinners:180-ring-with-bg justify-self-center" v-if="wait"/>
                        <span class="grid-col-2">Sign In</span>
                    </button>
                </form>
                <div class="grid-justify-self-stretch sm-grid-col-1-3 text-gray text-3">
                    By signing in, you agree to our <a class="link color-blue" href="https://cheeri.app/terms/" target="_blank">Terms of Use</a> and <a class="link color-blue" href="https://cheeri.app/terms/" target="_blank">Privacy Policy.</a>
                </div>
                <div class="color-gray-4 flex flex-justify-center grid-justify-self-center relative sm-grid-col-1-3 w-50%">
                    <div class="absolute b-1 b-t-solid top-50% w-100%"/>
                    <div class="bg-white p-l-2 p-r-2 relative">OR</div>
                </div>
                <GoogleButton v-on:fetch="wait = $event" v-bind:redirect="redirect"/>
                <LinkedInButton class="m-t-4 sm-m-0" v-bind:redirect="redirect"/>
            </div>
        </Validation>
        <Transition appear enterFromClass="opacity-0" enterToClass="opacity-100" leaveFromClass="opacity-100" leaveToClass="opacity-0">
            <div class="absolute bg-opacity-75 bg-white flex flex-items-center flex-justify-center transition-opacity transition-duration-250ms top-0 h-100% w-100%" v-if="wait">
                <span class="color-orange text-8 i-svg-spinners:90-ring-with-bg"/>
            </div>
        </Transition>
        <Transition appear enterFromClass="opacity-0" enterToClass="opacity-100" leaveFromClass="opacity-100" leaveToClass="opacity-0">
            <div class="absolute bg-opacity-95 bg-white flex flex-items-center flex-justify-center transition-opacity transition-duration-250ms top-0 h-100% w-100%" v-if="sent">
                <span class="color-green text-8 i-fa6-solid:envelope-circle-check m-r-4"/>
                <span class="text-4">Please check your email.</span>
            </div>
        </Transition>
    </div>
</template>