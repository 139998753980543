<script lang="ts" setup>
import {computed, ref} from "vue";
import {useVideoMetadata, videoMetadata} from "./VideoMetadata";
import Field from "../../components/Field.vue";
import Modal from "../../components/Modal.vue";
import ReportCard from "./ReportCard.vue";
import ReportCardItem from "./ReportCardItem.vue";
import {ReportMode} from "../ReportMode";
import Textbox from "../../components/Textbox.vue";
import Validation from "../../components/Validation.vue";

interface Emits
{
    (event: "remove"): void;
    (event: "update:value", value: [string | null, string | null]): void;
}
interface Props
{
    mode?: ReportMode;
    value?: [string | null, string | null];
}
const emit = defineEmits<Emits>();
const props = withDefaults(defineProps<Props>(),
{
    mode: ReportMode.View,
    value: () => [null, null]
});
const update = (value: [string | null, string | null]) => emit("update:value", value);

const uid = ref("");

const adding = ref<(() => Promise<void>) | null>(null);
const add = async (index: number) =>
{
    uid.value = "";
    adding.value = async () =>
    {
        adding.value = null;
        update([index === 0 ? uid.value : props.value[0], index === 1 ? uid.value : props.value[1]]);
    };
};

const remove = (index: number) =>
{
    if(props.value.every((v) => v === null))
    {
        emit("remove");
    }
    else
    {
        update([index === 0 ? null : props.value[0], index === 1 ? null : props.value[1]]);
    }
};
const urls = computed(() => props.value?.flatMap((id) => id === null ? [] : [id]) ?? []);
useVideoMetadata(urls);
</script>
<template>
    <ReportCard v-bind:mode="mode" v-bind:value="value">
        <template v-slot:edit>
            <ReportCardItem v-bind:index="index" v-bind:key="index" v-bind:mode="mode" v-bind:value="value" v-for="(id, index) of value" v-on:add="add(index)" v-on:remove="remove(index)">
                <div class="overflow-hidden b-rd-2 h-100% w-100%" v-if="id !== null">
                    <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen class="print-hidden h-100% w-100%" frameborder="0" v-bind:alt="id === null ? undefined : videoMetadata[id].title" v-bind:src="`https://www.youtube.com/embed/${id}`" v-bind:style="{aspectRatio: id === null ? undefined : videoMetadata[id].aspectRatio}"/>
                </div>
                <template v-slot:add>Add video card</template>
            </ReportCardItem>
            <Modal v-bind:visible="adding !== null" v-on:update:visible="adding = null">
                <template v-slot:default="{close}">
                    <h2>YouTube video ID</h2>
                    <p>Enter the YouTube video identifier.</p>
                    <Validation v-slot="{validate}">
                        <form class="flex flex-col flex-gap-9" v-on:submit.prevent="validate(true) && adding!()">
                            <Field label="Video ID" rule="YouTubeID" v-bind:value="uid" v-slot="{id}">
                                <Textbox v-bind:id="id" v-model:value="uid"/>
                            </Field>
                            <div class="flex flex-items-center flex-justify-around">
                                <button class="b-none b-rd-100vmax bg-yellow cursor-pointer min-h-10 min-w-48" type="submit">Add</button>
                                <button class="b-none b-rd-100vmax cursor-pointer min-h-10 min-w-48" type="button" v-on:click="close()">Cancel</button>
                            </div>
                        </form>
                    </Validation>
                </template>
            </Modal>
        </template>
        <template v-slot:preview>
            <ReportCardItem v-bind:mode="mode">
                <div class="flex flex-self-center p-3">
                    <div class="color-lightgray i-fa6-solid-film text-16"/>
                </div>
            </ReportCardItem>
        </template>
        <template v-slot:view>
            <div class="b-1 b-solid b-transparent grid grid-cols-[1fr_1fr] grid-gap-4 grid-items-center grid-justify-items-center page-break-inside-avoid">
                <template v-for="id of value">
                    <div v-if="id === null"/>
                    <template v-else>
                        <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen class="print-hidden h-100% w-100%" frameborder="0" v-bind:alt="id === null ? undefined : videoMetadata[id].title" v-bind:src="`https://www.youtube.com/embed/${id}`" v-bind:style="{aspectRatio: id === null ? undefined : videoMetadata[id].aspectRatio}"/>
                        <img class="hidden print-block print-max-h-100vh print-max-w-50vw" v-bind:src="`https://img.youtube.com/vi/${id}/hq3.jpg`"/>
                    </template>
                </template>
            </div>
        </template>
    </ReportCard>
</template>
