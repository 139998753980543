<script lang="ts" setup>
import ReportCard from "./ReportCard.vue";
import ReportCardItem from "./ReportCardItem.vue";
import {ReportMode} from "../ReportMode";
import Textarea from "../../components/Textarea.vue";

interface Statistic
{
    description: string;
    statistic: string;
}

interface Emits
{
    (event: "remove"): void;
    (event: "update:value", value: [Statistic | null, Statistic | null, Statistic | null]): void;
}
interface Props
{
    mode?: ReportMode;
    value?: [Statistic | null, Statistic | null, Statistic | null];
}
const emit = defineEmits<Emits>();
const props = withDefaults(defineProps<Props>(),
{
    mode: ReportMode.View,
    value: () => [{description: "", statistic: ""}, {description: "", statistic: ""}, {description: "", statistic: ""}]
});
const update = (value: [Statistic | null, Statistic | null, Statistic | null]) => emit("update:value", value);
const add = (index: number, value: Partial<Statistic>) => update([index === 0 ? {...props.value[0] ?? {description: "", statistic: ""}, ...value} : props.value[0], index === 1 ? {...props.value[1] ?? {description: "", statistic: ""}, ...value} : props.value[1], index === 2 ? {...props.value[2] ?? {description: "", statistic: ""}, ...value} : props.value[2]]);

const remove = (index: number) =>
{
    if(props.value.every((v) => v === null))
    {
        emit("remove");
    }
    else
    {
        update([index === 0 ? null : props.value[0], index === 1 ? null : props.value[1], index === 2 ? null : props.value[2]]);
    }
};
</script>
<template>
    <ReportCard v-bind:mode="mode" v-bind:value="value">
        <template v-slot:edit>
            <ReportCardItem v-bind:class="item === null ? '' : 'bg-gray text-white'" v-bind:index="index" v-bind:key="index" v-bind:mode="mode" v-bind:value="value" v-for="(item, index) of value" v-on:add="add(index, {})" v-on:remove="remove(index)">
                <div class="cursor-pointer h-100% parent" v-if="item !== null">
                    <input class="b-none bg-transparent box-border font-sans outline-none p-3 text-15 text-inherit w-100%" placeholder="123" type="text" v-bind:value="item.statistic" v-on:input="(e) => add(index, {statistic: (e.target as HTMLInputElement).value})"/>
                    <Textarea class="overflow-wrap-break-anywhere p-3 text-inherit" placeholder="Participants" v-bind:value="item.description" v-on:update:value="(description) => add(index, {description})"/>
                </div>
                <template v-slot:add>Add statistics card</template>
            </ReportCardItem>
        </template>
        <template v-slot:preview>
            <ReportCardItem class="bg-gray text-white" v-bind:mode="mode">
                <div class="p-3">
                    <div class="overflow-x-hidden text-15">123</div>
                    <div class="overflow-x-hidden">Participants</div>
                </div>
            </ReportCardItem>
        </template>
        <template v-slot:view>
            <div class="grid grid-cols-[repeat(3,1fr)] grid-gap-4 page-break-inside-avoid">
                <div class="b-rd-2 bg-gray color-adjust-exact p-3 text-white" v-bind:key="index" v-bind:mode="mode" v-for="(item, index) of value">
                    <div class="overflow-wrap-anywhere text-15">{{item?.statistic}}</div>
                    <div class="overflow-wrap-anywhere white-space-pre-wrap">{{item?.description}}&ZeroWidthSpace;</div>
                </div>
            </div>
        </template>
    </ReportCard>
</template>