import type {Navigation, Scope} from "./Navigation";
import {type RouteLocation, type RouteRecordRaw, type RouterScrollBehavior, createRouter, createWebHistory} from "vue-router";
import {AuthRoutes} from "./auth/AuthRoutes";
import Authorization from "./Authorization";
import type {Component} from "vue";
import NotFound from "./NotFound.vue";
import {ProjectRoute} from "./project/ProjectRoute";
import {TemplateRoute} from "./templates/TemplateRoute";
import {useRouteBreadcrumb} from "./Breadcrumb";

declare module "vue-router"
{
    interface RouteMeta
    {
        breadcrumb?: (texts: Partial<Record<string, string | undefined>>) => string;
        keepAlive?: boolean;
        layout?: Component;
        navigation?: (route: RouteLocation) => Navigation[];
        scopes?: Array<Scope>;
    }
}

const routes: RouteRecordRaw[] =
[
    {
        path: "/",
        redirect: () =>
        {
            const authorization = Authorization.decode();
            if(authorization === null)
            {
                return "/signin/";
            }
            else
            {
                return "/project/";
            }
        }
    },
    ...AuthRoutes,
    TemplateRoute,
    ProjectRoute,
    {
        component: NotFound,
        name: "404",
        path: "/:catchAll(.*)"
    }
];

const scrollBehavior: RouterScrollBehavior = (from, to, savedPosition) => from.meta.keepAlive === true ? savedPosition ?? ({top: 0}) : undefined;
const history = createWebHistory();
const router = createRouter({history, routes, scrollBehavior, sensitive: true, strict: true});
router.beforeEach((to, from) =>
{
    if(from.meta.message !== undefined && to.meta.message !== undefined)
    {
        to.meta.message = from.meta.message;
    }
    useRouteBreadcrumb(to, {});
});
router.beforeEach(async ({matched, path}) =>
{
    const authorization = Authorization.decode();
    const scopes = matched.reduce((set, {meta: {scopes = []}}) => scopes.reduce((set, scope) => set.add(scope), set), new Set<Scope>());
    if(authorization === null)
    {
        if(scopes.size > 0)
        {
            return `/signin/?redirect=${encodeURIComponent(path)}`;
        }
    }
    else if(scopes.has("subscriber"))
    {
        const {subscription} = authorization;
        if(subscription === "Expired")
        {
            return `/subscription/subscribe/?redirect=${encodeURIComponent(path)}`;
        }
    }
});

export default router;