import type {Ref} from "vue";

/**
 * Assigns an invokable function to the reference and resets the reference to <code>null</code> when the function is invoked.
 *
 * @param ref the reference to the invokable function.
 * @param fn the function to decorate.
 */
export const invokable = <A extends any[]>(ref: Ref<(() => Promise<void>) | null>, fn: (...args: A) => Promise<void>) =>
{
    return (...args: A) =>
    {
        ref.value = async () =>
        {
            ref.value = null;
            await fn(...args);
        };
    };
};