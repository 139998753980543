<script generic="T" lang="ts" setup>
import {inject, onMounted, onUnmounted, provide, ref, watch} from "vue";

interface Props
{
    validator?: (value?: T) => string[];
    value?: T;
}
const props = withDefaults(defineProps<Props>(),
{
    validator: () => []
});

interface Field
{
    errors: () => string[];
    indicate: () => void;
    validate: (indicating?: boolean) => boolean;
}
const context =
{
    add: (field: Field) => fields.add(field),
    remove: (field: Field) => fields.delete(field),
    reset: () => reset()
};
const fields: Set<Field> = new Set();
const parent = inject<typeof context | null>("Validation.context", null);
provide("Validation.context", context);

const error = ref<string | null>(null);
const errors = () =>
{
    const {validator} = props;
    const parent = validator(props.value);
    const children = Array.from(fields).map(({errors}) => errors());
    const messages = [parent, ...children];
    return messages.flat();
};
const indicate = () =>
{
    for(const {indicate} of fields)
    {
        indicate();
    }
    const messages = errors();
    const [message] = messages;
    error.value = message ?? null;
};
const reset = () =>
{
    if(parent !== null)
    {
        parent.reset();
    }
    error.value = null;
};

const validate = (indicating: boolean = false) =>
{
    const messages = errors();
    const valid = messages.length === 0;
    if(indicating)
    {
        indicate();
    }
    if(valid)
    {
        reset();
    }
    return valid;
};
watch(() => props.value, () => validate());

const field: Field = {errors, indicate, validate};
onMounted(() => parent?.add(field));
onUnmounted(() => parent?.remove(field));
defineExpose({error, validate});
</script>
<template>
    <slot v-bind:error="error" v-bind:validate="validate"/>
</template>
