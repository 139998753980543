import type {Ref} from "vue";
import {abortableWithAbort} from "./Abortable";

/**
 * Decorates the async function to set the wait flag to true while the function is running.
 *
 * @param fn the function to decorate.
 * @param wait the flag to set to true while the function is running.
 * @returns the decorated function.
 */
export function awaitable<A extends any[], P extends any>(wait: Ref<boolean>, fn: (...args: A) => Promise<P>)
{
    return async (...args: A) =>
    {
        wait.value = true;
        try
        {
            return await fn(...args);
        }
        finally
        {
            wait.value = false;
        }
    };
}

/**
 * Decorates the async function to set the selection to the selected value while the function is running.
 *
 * @param fn the function to decorate.
 * @param selection the reference to set to the selected value while the function is running.
 * @returns the decorated function.
 */
export function awaitableSelection<A extends any[], P extends any, T extends any>(selection: Ref<Set<T>>, fn: (selected: T, ...args: A) => Promise<P>): (selected: T, ...args: A) => Promise<P>;
export function awaitableSelection<A extends any[], P extends any, T extends any>(selection: Ref<Set<T>>, fn: (selected: T[], ...args: A) => Promise<P>): (selected: T[], ...args: A) => Promise<P>;
export function awaitableSelection<A extends any[], P extends any, T extends any>(selection: Ref<Set<T>>, fn: (selected: any, ...args: A) => Promise<P>): (selected: any, ...args: A) => Promise<P>
{
    return async (selected: any, ...args: A) =>
    {
        if(selected instanceof Array)
        {
            selected.forEach((value) => selection.value.add(value));
        }
        else
        {
            selection.value.add(selected);
        }
        try
        {
            return await fn(selected, ...args);
        }
        finally
        {
            if(selected instanceof Array)
            {
                selected.forEach((value) => selection.value.delete(value));
            }
            else
            {
                selection.value.delete(selected);
            }
        }
    };
}

/**
 * Decorates the async function to set the wait flag to true until the function completes witout being aborted.
 *
 * @param fn the function to decorate.
 * @param wait the flag to set to true while the function is running.
 * @returns the decorated abortable function.
 */
export function awaitableWithAbort<A extends any[], P extends any>(wait: Ref<boolean>, fn: (abort: AbortController, ...args: A) => Promise<P>)
{
    return abortableWithAbort(async (abort, ...args: A) =>
    {
        wait.value = true;
        try
        {
            return await fn(abort, ...args);
        }
        finally
        {
            if(abort.signal.aborted === false)
            {
                wait.value = false;
            }
        }
    });
}