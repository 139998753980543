<script lang="ts" setup>
import {computed, ref} from "vue";

interface Emits
{
    (event: "update:value", value: string): void;
}
interface Props
{
    placeholder?: string;
    singleline?: boolean;
    value?: string;
}
const emit = defineEmits<Emits>();
const props = withDefaults(defineProps<Props>(),
{
    placeholder: "",
    singleline: false,
    value: ""
});
const getValue = () =>
{
    if(props.singleline)
    {
        return props.value.replaceAll(/\n/g, " ");
    }
    else
    {
        return props.value;
    }
};
const setValue = (value: string) =>
{
    if(props.singleline)
    {
        emit("update:value", value.replaceAll(/\n/g, " "));
    }
    else
    {
        emit("update:value", value);
    }
};
const text = computed({get: getValue, set: setValue});
const textarea = ref<HTMLTextAreaElement>();
const enter = (e: KeyboardEvent) =>
{
    if(props.singleline)
    {
        const {target} = e;
        if(target instanceof HTMLTextAreaElement)
        {
            const {form} = target;
            form?.dispatchEvent(new Event("submit", {cancelable: true}));
            e.preventDefault();
        }
    }
};
const focus = () => textarea.value?.focus();
defineExpose({focus});
defineOptions({inheritAttrs: false});
</script>
<template>
    <div class="b-color-inherit color-inherit box-border relative">
        <pre class="box-border color-inherit font-sans m-0 overflow-wrap-anywhere p-0 text-4 text-transparent w-100% white-space-pre-wrap" v-bind="$attrs">{{value}}&ZeroWidthSpace;</pre>
        <textarea class="absolute b-0 b-none bg-transparent box-border color-inherit font-sans h-100% min-h-1lh outline-none overflow-wrap-anywhere p-0 resize-none text-4 top-0 w-100%" ref="textarea" v-bind="$attrs" v-bind:placeholder="placeholder" v-model="text" v-on:keydown.enter="enter"/>
    </div>
</template>