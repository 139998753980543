export default class Base64
{
    /**
     * Prevent instantiation.
     */
    private Base64()
    {
    }

    /**
     * Decodes a Base64 string.
     *
     * @param s the Base64 string to decode.
     * @returns the decoded string.
     */
    static decode(base64: string): string
    {
        return new TextDecoder().decode(Uint8Array.from(Array.from(window.atob(base64)).map((s) => s.codePointAt(0)!)));
    }

    /**
     * Encodes a string as Base64.
     *
     * @param s the string to encode.
     * @returns the Base64 encoded string.
     */
    static encode(s: string): string
    {
        return window.btoa(String.fromCharCode(...new TextEncoder().encode(s)));
    }
}