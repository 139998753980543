<script lang="ts" setup>
import {computed, onMounted, onUnmounted, ref, watch} from "vue";
import {disabled, saveFunction, working} from "./ReportToolbar.vue";
import API from "../API";
import type {Card} from "./ReportCards";
import Loader from "../components/Loader.vue";
import type {Report} from "../API";
import ReportEditor from "./ReportEditor.vue";
import type {ReportMode} from "./ReportMode";
import {useAutosave} from "../components/Autosave";

interface Props
{
    id?: string;
    mode: ReportMode.Edit | ReportMode.View;
    projectId: string;
    reportId: string;
}
const props = withDefaults(defineProps<Props>(), {});
const report = ref<Report | null>(null);
onMounted(async () =>
{
    const {projectId, reportId} = props;
    report.value = await API.fetch("get", "/project/{projectId}/report/{reportId}/", {projectId, reportId}, {}, null) ?? {cards: [], title: ""};
});

const getCards = (): Card[] => report.value?.cards ?? [];
const setCards = (value: Card[]) =>
{
    report.value!.cards = value.map(({card, value}) =>
    {
        if(card === "ChartCard")
        {
            return {card, value: value!};
        }
        else if(card === "ImageCard" || card === "VideoCard")
        {
            return {card, value: value ?? [null, null]};
        }
        else if(card === "StatisticsCard")
        {
            return {card, value: value ?? [null, null, null]};
        }
        else
        {
            return {card, value: value ?? ""};
        }
    });
    autosave();
};
const cards = computed({get: getCards, set: setCards});

const getTitle = () => report.value === null ? "" : report.value.title ?? "";
const setTitle = (value: string) =>
{
    report.value!.title = value;
    autosave();
};
const title = computed({get: getTitle, set: setTitle});

const {autosave, save, saving} = useAutosave("patch", "/project/{projectId}/report/{reportId}/", async (fetch) =>
{
    const {projectId, reportId} = props;
    await fetch({projectId, reportId}, {}, report.value!);
});

onMounted(() => saveFunction.value = () => save());
onUnmounted(() => saveFunction.value = () => {});

watch(report, () => disabled.value = report.value === null, {immediate: true});
watch(saving, () => working.value = saving.value);
</script>
<template>
    <Loader v-bind:loading="report === null">
        <keep-alive>
            <ReportEditor v-bind:id="id" v-bind:mode="mode" v-model:cards="cards" v-model:title="title"/>
        </keep-alive>
    </Loader>
</template>