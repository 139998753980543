import type {Request as ExpressRequest, Response as ExpressResponse} from "express";
import type {operations, paths} from "../target/API";
import type {JWTPayload} from "jose";

export type * from "../target/API";

type Extraneous<K extends keyof any> = {[_ in K]: never};
type ExcludeExtraneousProperties<Allowed, Actual extends Allowed> = Allowed extends object ? Actual & Extraneous<Exclude<keyof Actual, keyof Allowed>> : Allowed;
type ExcludeExtraneous<Allowed, Actual extends Allowed> = Allowed extends Array<infer AllowedItem> ? Actual extends Array<infer ActualItem extends AllowedItem> ? ExcludeExtraneous<AllowedItem, ActualItem>[] : ExcludeExtraneousProperties<Allowed, Actual> : ExcludeExtraneousProperties<Allowed, Actual>;
type FilterKeys<O, Matchers> = {[K in keyof O]-?: K extends Matchers ? O[K] : never}[keyof O];
type Headers<T> = {[P in keyof T as Lowercase<string & P>]: T[P]};
type JsonLike = `${string}json${string}`;
type Optional<K, V> = Exclude<K, undefined> extends never ? V : K;
type PickByType<T, V> = {[K in keyof T as T[K] extends V | undefined ? K : never]: T[K]}

type EndpointPart<Operation, Part, Type> = FilterKeys<FilterKeys<Operation, Part>, Type>;
type EndpointRequestBody<Operation> = FilterKeys<EndpointPart<Operation, "requestBody", "content">, JsonLike>;
type Parameter<Operation, Type> = EndpointPart<Operation, "parameters", Type>;
type ResponseCodes<Operation> = keyof FilterKeys<Operation, "responses">;
type GenericRequest<Operation, TokenPayload = unknown> = Omit<ExpressRequest<Parameter<Operation, "path">, {}, GenericRequestBody<Operation>, Exclude<Parameter<Operation, "query">, undefined>>, "headers"> & {headers: Exclude<Headers<Parameter<Operation, "header">>, undefined>, session: Session, token: TokenPayload};
type GenericRequestBody<Operation> = Operation & {requestBody: undefined} extends never ? EndpointRequestBody<Operation> : EndpointRequestBody<Operation> | undefined;
type GenericResponse<Operation> = Omit<ExpressResponse, "end" | "json" | "send" | "writeHead"> & {end: () => void; json: <T extends GenericResponseBody<Operation>>(res: ExcludeExtraneous<GenericResponseBody<Operation>, T>) => void; writeHead: (code: ResponseCodes<Operation>, status: string) => void};
type GenericResponseBody<Operation> = FilterKeys<FilterKeys<EndpointPart<Operation, "responses", 200 | 201>, "content">, JsonLike>;
type GenericHandler<Operation, TokenPayload = unknown> = (req: GenericRequest<Operation, TokenPayload>, res: GenericResponse<Operation>) => Promise<void>;

export type Path<M extends string = string> = keyof PickByType<{[K in keyof paths]: K extends string ? keyof paths[K] extends M ? M : M extends keyof paths[K] ? M : false : false}, M>;
export type PathEndpoint<P extends Path, M extends PathMethod<P>> = FilterKeys<paths, P>[M];
export type PathHandler<P extends Path, M extends PathMethod<P>, TokenPayload = unknown> = GenericHandler<PathEndpoint<P, M>, TokenPayload>;
export type PathMethod<P extends Path> = {[K in keyof Pick<paths, P>]: keyof paths[K]}[P];
export type PathRequestBody<P extends Path, M extends PathMethod<P>> = Optional<GenericRequestBody<PathEndpoint<P, M>>, null>;
export type PathRequestBodyStrict<P extends Path, M extends PathMethod<P>, T extends PathRequestBody<P, M>> = Optional<ExcludeExtraneous<PathRequestBody<P, M>, T>, null>;
export type PathResponseBody<P extends Path, M extends PathMethod<P>> = EndpointPart<PathEndpoint<P, M>, "responses", 204> extends never ? GenericResponseBody<PathEndpoint<P, M>> : (GenericResponseBody<PathEndpoint<P, M>> | null);
export type PathParams<P extends Path, M extends PathMethod<P>> = Optional<Parameter<PathEndpoint<P, M>, "path">, {}>;
export type PathQuery<P extends Path, M extends PathMethod<P>> = Optional<Parameter<PathEndpoint<P, M>, "query">, {}>;

export type Handler<Operation extends keyof operations, TokenPayload = unknown> = GenericHandler<FilterKeys<operations, Operation>, TokenPayload>;
export type Endpoints = {[P in keyof operations]: Handler<P, any>};
export type RequestBody<Operation extends keyof Endpoints> = GenericRequestBody<FilterKeys<operations, Operation>>;
export type Request<Operation extends keyof Endpoints> = GenericRequest<FilterKeys<operations, Operation>>;
export type Response<Operation extends keyof Endpoints> = GenericResponse<FilterKeys<operations, Operation>>;
export type ResponseBody<Operation extends keyof Endpoints> = GenericResponseBody<FilterKeys<operations, Operation>>;
export type Role = "administrator";
export type Subscription = "Active" | "Canceled" | "Expired" | "Trial";
export type Session = Required<Pick<JWTPayload, "exp" | "iat" | "sub">> & {roles: Role[]; subscription: Subscription; trial?: string};

/**
 * Creates the API server URL.
 *
 * @param base the URL base path.
 * @param path the API path.
 * @param params the API path parameters.
 * @param query the API query parameters.
 * @returns the API server URL.
 */
export function url<P extends Path, M extends PathMethod<P>>(base: string, path: P, params: PathParams<P, M>, query: PathQuery<P, M>)
{
    const uri = path.replace((/{([^}]+)}/g), (_, k) => (params as Record<any, any>)[k]);
    const url = new URL(`${base}${uri}`);
    const search = new URLSearchParams();
    for(const [key, value] of Object.entries(query as object))
    {
        if(value instanceof Array)
        {
            for(const v of value)
            {
                search.append(key, v);
            }
        }
        else
        {
            search.append(key, String(value));
        }
    }
    url.search = search.toString();
    return url;
}