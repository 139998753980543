import Report from "./Report.vue";
import {ReportMode} from "./ReportMode";
import ReportSidebar from "./ReportSidebar.vue";
import ReportToolbar from "./ReportToolbar.vue";
import Reports from "./Reports.vue";
import type {RouteRecordRaw} from "vue-router";
import {id} from "../RouteId";

const uid = `id_${Math.random().toString(36).substring(2)}`;

export const ReportRoute: RouteRecordRaw =
{
    children:
    [
        {
            component: Reports,
            path: "",
            props: ({params: {projectId}}) => ({projectId})
        },
        {
            children:
            [
                {
                    components:
                    {
                        default: Report,
                        sidebar: ReportSidebar,
                        toolbar: ReportToolbar
                    },
                    path: "",
                    props:
                    {
                        default: ({params: {projectId, reportId}}) => ({id: uid, mode: ReportMode.Edit, projectId, reportId}),
                        sidebar: ({params: {projectId}}) => ({id: uid, projectId}),
                        toolbar: ({params: {projectId, reportId}}) => ({mode: ReportMode.Edit, projectId, reportId})
                    }
                },
                {
                    components:
                    {
                        default: Report,
                        toolbar: ReportToolbar
                    },
                    path: "view/",
                    props:
                    {
                        default: ({params: {projectId, reportId}}) => ({id: uid, mode: ReportMode.View, projectId, reportId}),
                        toolbar: ({params: {projectId, reportId}}) => ({mode: ReportMode.View, projectId, reportId})
                    }
                }
            ],
            meta:
            {
                breadcrumb: (texts) => texts["reportId"] ?? "Report",
                navigation: () => []
            },
            path: `${id("reportId")}/`
        }
    ],
    meta:
    {
        breadcrumb: () => "Reports"
    },
    path: "reports/"
};