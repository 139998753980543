<script lang="ts" setup>
import {computed, ref, watchEffect} from "vue";
import Dropdown from "../../components/Dropdown.vue";
import Field from "../../components/Field.vue";
import Tex from "../../components/Tex.vue";
import type {Unit} from "../../API";

interface Emits
{
    (event: "update:unit", value: Unit | null): void;
}
const emit = defineEmits<Emits>();

interface Props
{
    disabled: boolean;
    readonly: boolean;
    unit: Unit | null;
}
const props = withDefaults(defineProps<Props>(), {});

type Category = "area" | "energy" | "greenhouse-gases" | "length" | "mass" | "none" | "time" | "volume";
const Categories: [Category, string][] =
[
    ["area", "Area"],
    ["none", "Count"],
    ["energy", "Energy"],
    ["greenhouse-gases", "Greenhouse gases"],
    ["length", "Length"],
    ["mass", "Mass"],
    ["time", "Time"],
    ["volume", "Volume"]
];
const Units: Record<Category, [Unit, string][]> =
{
    "area":
    [
        ["ft^2", "Square feet"],
        ["m^2", "Square meters"],
        ["Ha", "Hectare"]
    ],
    "energy":
    [
        ["Btu", "British thermal units"],
        ["GWh", "Gigawatt hours"],
        ["J", "Joules"],
        ["kWh", "Kilowatt hours"],
        ["MJ", "Megajoules"],
        ["Towngas_unit", "Towngas Unit"]
    ],
    "greenhouse-gases":
    [
        ["t CO2", "Metric tons of carbon dioxide"],
        ["t CH4", "Metric tons of methane"],
        ["t N2O", "Metric tons of nitrous oxide"]
    ],
    "length":
    [
        ["ft", "Feet"],
        ["m", "Meters"],
        ["mi", "Miles"],
        ["km", "Kilometers"]
    ],
    "mass":
    [
        ["kg", "Kilograms"],
        ["t", "Metric tons"],
        ["lb", "Pounds"]
    ],
    "none":
    [
        ["", ""]
    ],
    "time":
    [
        ["s", "Seconds"],
        ["minute", "Minutes"],
        ["h", "Hours"],
        ["d", "Days"],
        ["y", "Years"]
    ],
    "volume":
    [
        ["ft^3", "Cubic feet"],
        ["m^3", "Cubic meters"],
        ["l", "Liters"]
    ]
};
const category = ref<Category | null>(null);

const getValue = () => category.value === null ? null : props.unit;
const setValue = (value: Unit | null) => emit("update:unit", category.value === null || value === null ? null : value);
const value = computed({get: getValue, set: setValue});

const units = computed(() => category.value === null ? [] : Units[category.value]);
watchEffect(() =>
{
    const [selected] = Categories.find(([category]) => Units[category].some(([value]) => value === props.unit)) ?? [];
    category.value = selected ?? null;
});
</script>
<template>
    <Field label="Unit Category" rule="NotNull" v-bind="$attrs" v-bind:value="category" v-slot="{id}">
        <Dropdown v-bind:disabled="disabled" v-bind:id="id" v-bind:items="Categories" v-bind:readonly="readonly" v-model:selected="category"/>
    </Field>
    <Field label="Unit" rule="NotNull" v-bind="$attrs" v-bind:value="value" v-if="units !== undefined && units.length > 1" v-slot="{id}">
        <Dropdown v-bind:disabled="disabled" v-bind:id="id" v-bind:items="units" v-bind:readonly="readonly" v-model:selected="value">
            <template v-bind:key="index" v-slot:[index] v-for="([unit, label], index) of units">
                <span>{{label}} (<Tex type="unit" v-bind:expression="unit"/>)</span>
            </template>
        </Dropdown>
    </Field>
</template>