import Analyze from "./Analyze.vue";
import type {RouteRecordRaw} from "vue-router";

export const AnalyzeRoute: RouteRecordRaw =
{
    children:
    [
        {
            component: Analyze,
            path: "",
            props: ({params: {projectId}}) => ({projectId})
        }
    ],
    meta:
    {
        breadcrumb: () => "Indicators"
    },
    path: "analyze/"
};