<script lang="ts" setup>
import {ref, watch} from "vue";

interface Emits
{
    (event: "update:expanded", expanded: boolean): void;
}
interface Props
{
    expanded?: boolean;
}
const emit = defineEmits<Emits>();
const props = withDefaults(defineProps<Props>(),
{
    expanded: true
});

const expanded = ref(props.expanded);
const toggle = () => expanded.value = !expanded.value;
watch(props, () => expanded.value = props.expanded);
watch(expanded, () => emit("update:expanded", expanded.value));
</script>
<template>
    <div class="b-rd-2 bg-verylightgray transition-padding p-x-6" v-bind:class="expanded ? 'p-y-6' : 'p-y-3'">
        <div class="cursor-pointer flex font-bold justify-between items-center parent" v-on:click="toggle">
            <slot name="header"/>
            <span class="i-material-symbols:keyboard-arrow-down-rounded parent-hover-text-black text-gray transition-transform text-8" v-bind:class="expanded ? '' : 'rotate--180'"/>
        </div>
        <div class="box-border overflow-y-hidden transition-max-height" v-bind:class="expanded ? 'max-h-15em' : 'max-h-0'">
            <div class="p-t-3">
                <slot/>
            </div>
        </div>
    </div>
</template>
