<script lang="ts" setup>
import {DateTime, type DateTimeUnit, Duration, Interval} from "luxon";
import type {IndicatorFormula, IndicatorFrequency, IndicatorType, Measurement, Unit} from "../API";
import {computed, nextTick, ref, watch} from "vue";
import Button from "../components/Button.vue";
import Checkbox from "../components/Checkbox.vue";
import type {ComponentExposed} from "vue-component-type-helpers";
import Field from "../components/Field.vue";
import Formula from "./Formula.vue";
import Loader from "../components/Loader.vue";
import Tex from "../components/Tex.vue";
import Textbox from "../components/Textbox.vue";
import Timeline from "./Timeline.vue";
import Validation from "../components/Validation.vue";
import {useDecimal} from "../components/Decimal";

interface Indicator
{
    currency?: string;
    description?: string;
    formula?: IndicatorFormula;
    frequency?: IndicatorFrequency;
    measurements?: number;
    name?: string;
    options?: string[],
    type?: IndicatorType;
    unit?: Unit;
}

interface Emits
{
    (event: "update:actual", value: Measurement): void;
    (event: "update:interval", value: Interval): void;
    (event: "remove"): void;
    (event: "submit"): void;
}

interface Props
{
    actual: Measurement | null;
    date: string | null;
    dates: string[];
    indicator: Indicator | null;
    refreshing: boolean;
    removing: boolean;
    saving: boolean;
}

const emit = defineEmits<Emits>();
const props = withDefaults(defineProps<Props>(), {});

const interval = computed(() =>
{
    const {date, indicator} = props;
    if(indicator === null)
    {
        return null;
    }
    else
    {
        const frequency = indicator.frequency!;
        if(frequency === "P0")
        {
            const epoch = DateTime.fromMillis(0, {zone: "utc"});
            return Interval.fromDateTimes(epoch, epoch);
        }
        else
        {
            const units: Record<Exclude<typeof frequency, "P0">, DateTimeUnit> =
            {
                "P1D": "day",
                "P1M": "month",
                "P1W": "week",
                "P1Y": "year",
                "P3M": "quarter"
            };
            const unit = units[frequency];
            const now = DateTime.utc().startOf(unit);
            if(date === null)
            {
                return Interval.fromDateTimes(now, now.endOf(unit));
            }
            else
            {
                const t1 = now.endOf(unit);
                const t0 = t1.minus(Duration.fromObject({year: 3})).startOf(unit);
                const t = DateTime.fromISO(date, {zone: "utc"});
                if(t.isValid)
                {
                    if(t < t0)
                    {
                        return Interval.fromDateTimes(t0, t0.endOf(unit));
                    }
                    else if(t > t1)
                    {
                        return Interval.fromDateTimes(t1, t1.endOf(unit));
                    }
                    else
                    {
                        return Interval.fromDateTimes(t.startOf(unit), t.endOf(unit));
                    }
                }
                else
                {
                    return Interval.fromDateTimes(now.startOf(unit), now.endOf(unit));
                }
            }
        }
    }
});

const measurement = ref<Measurement | null>(null);
const createEmptyMeasurement = (type: IndicatorType) =>
{
    switch(type)
    {
        case "formula":
        case "multiple-choice":
        {
            return {date: "", type, value: []};
        }
        case "monetary-value":
        case "number":
        case "unit":
        {
            return {date: "", type, value: Number.NaN};
        }
        case "single-choice":
        {
            return {date: "", type, value: -1};
        }
        case "text":
        {
            return {date: "", type, value: ""};
        }
        default:
        {
            return null;
        }
    }
};

const getChecked = () =>
{
    const {indicator} = props;
    if(measurement.value === null)
    {
        return indicator!.options!.map(() => false);
    }
    else if(measurement.value.type === "multiple-choice")
    {
        const value = measurement.value.value;
        return indicator!.options!.map((_, index) => value.includes(index));
    }
    else
    {
        return [];
    }
};
const setChecked = (value: boolean[]) => measurement.value!.value = value.flatMap((selected, index) => selected ? [index] : []);
const checked = computed({get: getChecked, set: setChecked});

const getDecimal = () =>
{
    if(measurement.value === null)
    {
        return Number.NaN;
    }
    else if(measurement.value.type === "monetary-value" || measurement.value.type === "number" || measurement.value.type === "unit")
    {
        return measurement.value.value;
    }
    else
    {
        return Number.NaN;
    }
};
const setDecimal = (value: number) => measurement.value!.value = value;
const decimal = useDecimal(computed({get: getDecimal, set: setDecimal}));

const getFormulaValues = (): number[] =>
{
    if(measurement.value === null)
    {
        return [];
    }
    else if(measurement.value.type === "formula")
    {
        return measurement.value.value;
    }
    else
    {
        return [];
    }
};
const setFormulaValues = (values: number[]) =>
{
    if(measurement.value!.type === "formula")
    {
        measurement.value!.value = values;
        update();
    }
};
const formulaValues = computed({get: getFormulaValues, set: setFormulaValues});

const title = computed(() =>
{
    const {indicator} = props;
    if(indicator === null || interval.value === null)
    {
        return "";
    }
    else
    {
        switch(indicator.frequency)
        {
            case "P0":
            {
                return "Once";
            }
            case "P1D":
            {
                return interval.value.start?.toFormat("yyyy-MM-dd");
            }
            default:
            {
                return interval.value.toFormat("yyyy-MM-dd", {separator: " to "});
            }
        }
    }
});

const select = async (interval: Interval) => emit("update:interval", interval);
watch(interval, async () => emit("update:interval", interval.value!));

watch([props, interval], () =>
{
    const {actual, indicator} = props;
    if(indicator === null)
    {
        measurement.value = null;
    }
    else
    {
        if(actual === null)
        {
            measurement.value = createEmptyMeasurement(indicator.type!);
        }
        else
        {
            measurement.value = actual;
        }
    }
});

const validation = ref<ComponentExposed<typeof Validation>>();
const update = () =>
{
    nextTick(async () =>
    {
        if(validation.value?.validate(false))
        {
            emit("update:actual", measurement.value!);
        }
    });
};
const submit = async () =>
{
    if(validation.value!.validate(true))
    {
        emit("submit");
    }
};

const removable = computed(() => props.dates.includes(interval.value!.start!.toISODate()!));
const remove = () => emit("remove");
const group = Math.random().toString(36).substring(2);

</script>
<template>
    <div class="flex flex-col h-100%">
        <div v-if="indicator !== null">
            <h1>{{indicator.name}}</h1>
            <Timeline v-bind:existing="dates" v-bind:frequency="indicator.frequency!" v-bind:selected="interval" v-on:update:selected="select"/>
            <div class="flex flex-gap-4 flex-items-top flex-justify-space-between">
                <h2 class="flex-grow-1">{{title}}</h2>
                <div class="flex flex-items-center">
                    <a class="link" role="button" v-bind:aria-disabled="removing || removable === false" v-on:click="remove">
                        <span class="inline-block i-svg-spinners:180-ring-with-bg h-4 m-r-1 w-4" v-show="removing"/>
                        <span class="inline-block">Clear input</span>
                    </a>
                </div>
            </div>
        </div>
        <div class="flex-grow-1" style="contain: layout">
            <Loader v-bind:loading="indicator === null || measurement === null || interval === null || refreshing">
                <Validation ref="validation">
                    <form class="flex flex-col flex-gap-5" v-on:submit.prevent="submit()" v-if="indicator !== null && measurement !== null">
                        <Field rule="Money" v-bind:label="indicator.description" v-bind:value="decimal" v-if="measurement.type === 'monetary-value'" v-slot="{id}">
                            <div class="flex flex-items-center">
                                <div class="m-r-2 text-gray">{{indicator.currency}}</div>
                                <Textbox v-bind:disabled="refreshing" v-bind:id="id" v-model:value="decimal" v-on:update:value="update()"/>
                            </div>
                        </Field>
                        <Field rule="Number" v-bind:label="indicator.description" v-bind:value="decimal" v-if="measurement.type === 'number'" v-slot="{id}">
                            <Textbox v-bind:disabled="refreshing" v-bind:id="id" v-model:value="decimal" v-on:update:value="update()"/>
                        </Field>
                        <Field rule="NonEmptyString" v-bind:label="indicator.description" v-bind:value="measurement.value" v-if="measurement.type === 'text'" v-slot="{id}">
                            <Textbox v-bind:disabled="refreshing" v-bind:id="id" v-model:value="measurement.value" v-on:update:value="update()"/>
                        </Field>
                        <Field rule="OneOrMoreOptions" v-bind:label="indicator.description" v-bind:value="checked" v-if="measurement.type === 'multiple-choice'">
                            <div class="m-t-4" v-bind:key="index" v-for="(option, index) of indicator.options">
                                <Checkbox v-bind:disabled="refreshing" v-bind:name="group" v-bind:label="option" v-bind:value="checked[index]" v-on:update:value="(selected) => (checked = checked.map((v, n) => n === index ? selected : v), update())"/>
                            </div>
                        </Field>
                        <Field rule="OneOption" v-bind:label="indicator.description" v-bind:value="measurement.value" v-if="measurement.type === 'single-choice'">
                            <div class="m-t-4" v-bind:key="index" v-for="(option, index) of indicator.options">
                                <Checkbox v-bind:disabled="refreshing" v-bind:name="group" v-bind:label="option" v-bind:radio="true" v-bind:value="measurement.value === index" v-on:update:value="(selected) => (selected ? measurement!.value = index : void null, update())"/>
                            </div>
                        </Field>
                        <Field rule="Number" v-bind:label="indicator.description" v-bind:value="decimal" v-if="measurement.type === 'unit'" v-slot="{id}">
                            <div class="flex flex-items-center">
                                <Textbox v-bind:disabled="refreshing" v-bind:id="id" v-model:value="decimal" v-on:update:value="update()"/>
                                <Tex class="m-l-2 text-gray white-space-nowrap" type="unit" v-bind:expression="indicator.unit"/>
                            </div>
                        </Field>
                        <Formula v-bind:date="date!" v-bind:formula="indicator.formula!" v-bind:refreshing="refreshing" v-model:values="formulaValues" v-if="measurement.type === 'formula'"/>
                        <Button class="flex-self-center" role="primary" type="submit" v-bind:disabled="refreshing" v-bind:loading="saving">Save</Button>
                    </form>
                </Validation>
            </Loader>
        </div>
    </div>
</template>
