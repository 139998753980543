<script lang="ts" setup>
import type {IndicatorFormulaParameters, Unit} from "../../API";
import Field from "../../components/Field.vue";
import IndicatorUnit from "./IndicatorUnit.vue";
import Tex from "../../components/Tex.vue";
import Textbox from "../../components/Textbox.vue";

interface Emits
{
    (event: "update:parameters", value: IndicatorFormulaParameters): void;
}
interface Props
{
    disabled: boolean;
    names: Set<string>;
    parameters: IndicatorFormulaParameters;
    readonly: boolean;
}
const emit = defineEmits<Emits>();
const props = withDefaults(defineProps<Props>(), {});

const getName = (name: string) => props.parameters?.[name]?.name ?? "";
const setName = (name: string, value: string) =>
{
    const parameter = props.parameters[name] ?? {name: value};
    const parameters = {...props.parameters, [name]: {...parameter, name: value}};
    emit("update:parameters", parameters);
};

const getUnit = (name: string) => props.parameters?.[name]?.unit ?? null;
const setUnit = (name: string, value: Unit | null) =>
{
    const parameter = props.parameters[name] ?? {unit: value!};
    const parameters = {...props.parameters, [name]: {...parameter, unit: value!}};
    emit("update:parameters", parameters);
};
</script>
<template>
    <template v-bind:key="index" v-for="(name, index) in names">
        <div class="b b-b-solid b-color-lightgray text-3">Parameter <Tex type="variable" v-bind:expression="name"/></div>
        <Field class="m-l-3" label="Measurement" rule="NonEmptyString" v-bind:value="getName(name)" v-slot="{id}">
            <Textbox v-bind:disabled="disabled" v-bind:id="id" v-bind:readonly="readonly" v-bind:value="getName(name)" v-on:update:value="(value) => setName(name, value)"/>
        </Field>
        <IndicatorUnit class="m-l-3" v-bind:disabled="disabled" v-bind:optional="true" v-bind:readonly="readonly" v-bind:unit="getUnit(name)" v-on:update:unit="(value) => setUnit(name, value)"/>
    </template>
</template>