<script generic="T" lang="ts" setup>
import {computed, nextTick, onBeforeUnmount, onMounted, ref, watchEffect} from "vue";
import {debounce} from "./Debounce";

type Emits =
{
    (e: "update:selected", selected: T | null): void
};
type Props =
{
    disabled?: boolean;
    items?: [T, string, ...unknown[]][];
    placeholder?: string;
    readonly?: boolean;
    selected?: T | null;
};
const emit = defineEmits<Emits>();
const props = withDefaults(defineProps<Props>(),
{
    disabled: false,
    items: () => [],
    placeholder: "",
    readonly: false,
    selected: null
});
const select = (index: number) =>
{
    const [value] = props.items[index];
    emit("update:selected", value);
};

const el = ref<HTMLDivElement>();
const popup = ref(false);
const dropdown = () =>
{
    if(props.disabled === false && props.readonly === false)
    {
        popup.value = !popup.value;
        el.value!.focus();
        nextTick(() => scrollIntoView());
    }
};

const id = `id_${Math.random().toString(36).substring(2)}`;
const dismiss = (event: MouseEvent) =>
{
    if(event.target)
    {
        const target = event.target as HTMLElement;
        if(target.closest(`[data-id=${id}]`) === null)
        {
            popup.value = false;
        }
    }
};
onMounted(() => window.addEventListener("click", dismiss));
onBeforeUnmount(() => window.removeEventListener("click", dismiss));

const list = ref<HTMLDivElement>();
const scrollIntoView = () => list.value!.querySelector("div[aria-selected='true']")?.scrollIntoView({behavior: "instant", block: "nearest"});

const index = computed(() => props.items.findIndex(([v]) => props.selected === v));
const next = () =>
{
    if(props.disabled === false)
    {
        const next = index.value + 1;
        if(next < props.items.length)
        {
            select(next);
            nextTick(() => scrollIntoView());
        }
    }
};
const previous = () =>
{
    if(props.disabled === false)
    {
        const previous = index.value - 1;
        if(previous >= 0)
        {
            select(previous);
            nextTick(() => scrollIntoView());
        }
    }
};

const query: string[] = [];
const reset = debounce(500, () => query.splice(0));
const search = (e: KeyboardEvent) =>
{
    if(e.key.length === 1)
    {
        query.push(e.key);
        const term = query.join("").toLowerCase();
        const index = props.items.findIndex(([_, label]) => label.toLowerCase().startsWith(term));
        if(index !== -1)
        {
            select(index);
            nextTick(() => scrollIntoView());
        }
        reset();
    }
};
watchEffect(() =>
{
    if(props.items.every(([v]) => props.selected !== v) && props.selected !== null)
    {
        emit("update:selected", null);
    }
});
</script>
<template>
    <div aria-selectable class="outline-none relative" ref="el" role="combobox" tabindex="0" v-bind:aria-disabled="disabled" v-bind:class="[disabled ? '' : 'cursor-pointer']" v-bind:data-id="id" v-on:keydown.down="next" v-on:keydown.enter="dropdown" v-on:keydown.space="dropdown" v-on:keydown.up="previous" v-on:keydown="search">
        <div v-bind:class="readonly ? 'b-b-color-transparent' : 'b-b-color-inherit'" class="b-b-1 b-b-solid grid grid-items-center grid-cols-[1fr_max-content] min-h-10" v-on:mousedown.prevent.stop="dropdown">
            <div class="color-middlegray overflow-hidden text-ellipsis white-space-nowrap" v-if="index === -1">{{placeholder}}</div>
            <div class="overflow-hidden" v-else>
                <slot v-bind:name="index">
                    <div class="overflow-hidden text-ellipsis white-space-nowrap">{{items[index][1]}}</div>
                </slot>
            </div>
            <div class="m-l-2 triangle-0.4" v-bind:class="[disabled ? 'color-middlegray' : 'color-black']" v-if="readonly === false"/>
        </div>
        <div class="absolute bg-white block b-1 b-color-verylightgray b-t-0 b-solid b-rd-b-2 box-border shadow font-sans max-h-40 overflow-y-auto transform-origin-t transition-transform w-100% z-1" ref="list" v-bind:class="popup ? 'transform-scale-y-100' : 'transform-scale-y-0'" v-on:transitionstart="scrollIntoView">
            <div class="block box-border color-black flex flex-items-center font-sans h-10 p-2" role="option" v-bind:aria-selected="selected === value" v-bind:class="selected === value ? 'bg-gray-2' : 'bg-white'" v-bind:key="index" v-bind:value="index" v-for="([value, label], index) in items" v-on:mousedown="select(index)" v-on:mouseup="popup = false">
                <slot v-bind:name="index">
                    <div class="overflow-hidden text-ellipsis white-space-nowrap">{{label}}</div>
                </slot>
            </div>
        </div>
    </div>
</template>