import {type AbortablePromise, abortablePromise} from "./AbortablePromise";

export function createWorkerModule<T extends Record<string, (...args: any[]) => any>>(worker: T)
{
    addEventListener("message", async ({data: {args, name}}) =>
    {
        const f = worker[name];
        try
        {
            const result = await f(...args);
            postMessage({result});
        }
        catch(error)
        {
            postMessage({error});
        }
        close();
    });
}

export function invoke<T extends {[key: string]: (...args: any[]) => any}, F extends keyof T>(worker: Worker, name: F, ...args: Parameters<T[F]>): AbortablePromise<ReturnType<T[F]>>
{
    const promise = new Promise<ReturnType<T[F]>>((resolve, reject) =>
    {
        worker.postMessage({args, name});
        worker.addEventListener("message", ({data}) =>
        {
            const {error, result} = data;
            if(error === undefined)
            {
                resolve(result);
            }
            else
            {
                reject(error);
            }
            worker.terminate();
        });
    });
    return abortablePromise(promise, () => worker.terminate());
}