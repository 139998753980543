import {isProxy, isReactive, isRef, toRaw} from "vue";

/**
 * Decorates the function to debounce it.
 *
 * @param fn the function to debounce.
 * @param delay the delay in milliseconds.
 * @returns the debounced function.
 */
export function debounce<A extends any[], P extends any>(delay: number, fn: (...args: A) => P)
{
    const {debounce} = debounceWithCancel(delay, fn);
    return debounce;
}

/**
 * Decorates the function to debounce it.
 *
 * @param fn the function to debounce.
 * @param delay the delay in milliseconds.
 * @returns the cancel and debounce functions.
 */
export function debounceWithCancel<A extends any[], P extends any>(delay: number, fn: (...args: A) => P)
{
    let timer: number;
    const cancel = () => window.clearTimeout(timer);
    const debounce = (...args: A) => new Promise<P>((resolve) =>
    {
        const cloned = structuredClone(deepToRaw(args));
        cancel();
        timer = window.setTimeout(() => resolve(fn(...cloned)), delay);
    });
    return {cancel, debounce};
}

export function deepToRaw<T extends Record<string, any>>(o: T): T
{
    const unref = (v: any): any =>
    {
        if(v === null)
        {
            return null;
        }
        else if(Array.isArray(v))
        {
            return v.map((item) => unref(item));
        }
        else if(isProxy(v) || isReactive(v) || isRef(v))
        {
            return unref(toRaw(v));
        }
        else if(typeof v === "object")
        {
            return Object.keys(v).reduce((o, key) => (o[key as keyof typeof o] = unref(v[key]), o), {} as T);
        }
        else
        {
            return v;
        }
    };
    return unref(o);
}