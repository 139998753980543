<script lang="ts" setup>
import {DateTime, type DateTimeUnit, Duration, Interval} from "luxon";
import {computed, onMounted, ref} from "vue";
import type {IndicatorFrequency} from "../API";

type Emits =
{
    (e: "update:selected", selected: Interval): void
};
interface Props
{
    existing?: string[];
    frequency: IndicatorFrequency;
    selected?: Interval | null;
}
const emit = defineEmits<Emits>();
const props = withDefaults(defineProps<Props>(),
{
    existing: () => [],
    selected: null
});

const format = computed(() =>
{
    const {frequency} = props;
    const formats: Record<typeof frequency, (interval: Interval) => string> =
    {
        "P0": () => "",
        "P1D": (interval) => interval.start!.toFormat("dd"),
        "P1M": (interval) => interval.start!.toFormat("LLL"),
        "P1W": (interval) => `${interval.start!.toFormat("dd")} - ${interval.end!.minus({day: 1}).endOf("week").toFormat("dd")}`,
        "P1Y": (interval) => interval.start!.toFormat("yyyy"),
        "P3M": (interval) => `${interval.start!.toFormat("LLL")} - ${interval.end!.minus({month: 1}).endOf("quarter").toFormat("LLL")}`
    };
    return formats[frequency];
});
const timeline = computed<[year: number, months: [month: number, days: Interval[]][]][] | null>(() =>
{
    const {frequency} = props;
    if(frequency === "P0")
    {
        return null;
    }
    else
    {
        const units: Record<Exclude<typeof frequency, "P0">, DateTimeUnit> =
        {
            "P1D": "day",
            "P1M": "month",
            "P1W": "week",
            "P1Y": "year",
            "P3M": "quarter"
        };
        const unit = units[frequency];
        const duration = Duration.fromObject({[unit]: 1});
        const t1 = DateTime.utc().endOf(unit);
        const t0 = t1.minus(Duration.fromObject({year: 3})).startOf(unit);
        const dates = Interval.fromDateTimes(t0, t1).splitBy(duration).map(({start}) => Interval.fromDateTimes(start!, start!.endOf(unit)));
        const yyyy = Array.from<[number, Interval[]]>(dates.reduce((map, date) => (map.has(date.start!.year) ? map.get(date.start!.year)!.push(date) : map.set(date.start!.year, [date]), map), new Map<number, Interval[]>()).entries());
        if(frequency === "P1Y")
        {
            return yyyy.map<[number, [number, Interval[]][]]>(([_, days]) => [0, [[0, days]]]);
        }
        else if(frequency === "P1M" || frequency === "P3M")
        {
            return yyyy.map<[number, [number, Interval[]][]]>(([year, days]) => [year,[[0, days]]]);
        }
        else
        {
            return yyyy.map<[number, [number, Interval[]][]]>(([year, days]) => [year, Array.from(days.reduce((map, date) => (map.has(date.start!.month) ? map.get(date.start!.month)!.push(date) : map.set(date.start!.month, [date]), map), new Map<number, Interval[]>()).entries())]);
        }
    }
});

const scroller = ref<HTMLDivElement | null>(null);
const scroll = (e: MouseEvent) =>
{
    const div = e.currentTarget as HTMLElement;
    const rect = scroller.value!.getBoundingClientRect();
    const x = e.clientX - rect.left < scroller.value!.clientWidth / 2 ? div.offsetLeft : div.offsetLeft + div.clientWidth;
    const y = e.clientY - rect.top < scroller.value!.clientWidth / 2 ? div.offsetLeft : div.offsetLeft + div.clientWidth;
    scroller.value!.scrollTo({behavior: "smooth", left: x, top: y});
};

const select = (interval: Interval) =>
{
    const {selected} = props;
    if(selected === null || selected.equals(interval) === false)
    {
        emit("update:selected", interval);
    }
};

onMounted(() =>
{
    const {selected} = props;
    if(selected !== null && scroller.value !== null)
    {
        const div = scroller.value.querySelector(`[data-date="${selected.toISODate()}"]`);
        if(div !== null)
        {
            div.scrollIntoView({behavior: "instant"});
        }
    }
});
</script>
<template>
    <div class="bg-verylightgray flex flex-row m-b-4 no-scrollbar relative overflow-x-scroll w-100%" ref="scroller" v-if="timeline !== null">
        <div class="b-1 not-last-b-r-solid b-r-middlegray" v-for="[year, months] of timeline" v-on:click="scroll">
            <div aria-label="year" class="inline-block left-0 p-1 p-l-4 p-r-4 sticky" v-if="year !== 0">{{year}}</div>
            <div class="flex flex-row">
                <div class="not-last-b-r-solid b-r-middlegray relative" v-for="[month, days] of months" v-on:click.stop="scroll">
                    <div aria-label="month" class="inline-block left-0 p-1 p-l-4 p-r-4 sticky" v-if="month !== 0">{{DateTime.fromObject({month}).toFormat("LLLL")}}</div>
                    <div class="flex flex-row">
                        <div aria-label="day" class="b-1 b-b-solid not-last-b-r-solid b-b-verylightgray b-r-verylightgray cursor-pointer flex p-2 p-l-4 p-r-4 white-space-nowrap" v-bind:class="selected?.toISODate() === interval.toISODate() ? 'bg-green text-white' : existing.includes(interval.start!.toISODate()!) ? 'bg-lightgray' : 'bg-white'" v-bind:data-date="interval.toISODate()" v-for="interval of days" v-on:click.stop="(e) => ((e.currentTarget as HTMLDivElement).scrollIntoView({behavior: 'smooth'}), select(interval))">{{format(interval)}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
