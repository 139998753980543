import {type Ref, type UnwrapRef, ref, watch} from "vue";

/**
 * Uses a preference setting.
 *
 * @param setting the preference setting key.
 * @param initial the initial value.
 */
export function useBooleanPreference(setting: string, initial: boolean)
{
    const stored = window.localStorage.getItem(setting);
    const value = ref(stored === null ? initial : !initial);
    watch(value, (value) =>
    {
        if(value === initial)
        {
            window.localStorage.removeItem(setting);
        }
        else
        {
            window.localStorage.setItem(setting, "");
        }
    });
    return value;
}

/**
 * Uses a preference setting.
 *
 * @param setting the preference setting key.
 * @param initial the initial value.
 */
export function useJsonPreference<T>(setting: Ref<string>, version: number, initial: UnwrapRef<T>)
{
    const name = `${setting.value}.v${version}`;
    const stored = window.localStorage.getItem(name);
    const value = ref(stored === null ? initial : JSON.parse(stored) as T);
    watch(setting, (setting) =>
    {
        const name = `${setting}.v${version}`;
        const stored = window.localStorage.getItem(name);
        value.value = stored === null ? initial : JSON.parse(stored) as UnwrapRef<T>;
    });
    watch(value, (value) =>
    {
        const name = `${setting.value}.v${version}`;
        if(value === null || value === initial)
        {
            window.localStorage.removeItem(name);
        }
        else
        {
            window.localStorage.setItem(name, JSON.stringify(value));
        }
    });
    return value;
}