<script setup lang="ts">
import {type Template, useGHG} from "../GHG";
import {computed, onMounted, ref} from "vue";
import Button from "../../components/Button.vue";
import Dropdown from "../../components/Dropdown.vue";
import Field from "../../components/Field.vue";
import Table from "../../components/Table.vue";
import Textbox from "../../components/Textbox.vue";
import Validation from "../../components/Validation.vue";
import {filterTemplates} from "../TemplateFilter";
import {useProgressBar} from "../../Wizard";

const ghg = useGHG();
const page = "mobile";

const templates = ref<Template[]>();
onMounted(async () => templates.value = await ghg.value.templates);

const skip = () => ghg.value.next(page);
const next = () =>
{
    ghg.value.save(page, templates.value!, rows.value);
    skip();
};
useProgressBar(() => ghg.value.progress(page));

const vehicles = computed(() => templates.value === undefined ? [] : filterTemplates(templates.value, {}, "vehicle"));
const fuels = (vehicle: string) => templates.value === undefined ? [] : filterTemplates(templates.value, {vehicle}, "fuel");

interface Row
{
    name: string;
    vehicle: string | null;
    fuel: string | null;
}
const rows = ref<Row[]>([{fuel: null, name: "", vehicle: null}]);

const add = () => rows.value.push({fuel: null, name: "", vehicle: null});
const remove = (index: number) => rows.value.splice(index, 1);
</script>
<template>
    <h1>Add mobile sources</h1>
    <p>Add all mobile vehicles using diesel oil, unleaded petrol, etc.</p>
    <Validation>
        <template v-slot:default="{validate}">
            <Table collapse-last v-bind:columns="4" v-bind:loading="templates === undefined" v-bind:rows="templates === undefined ? [] : rows">
                <template v-slot:header:1>Vehicle name</template>
                <template v-slot:header:2>Vehicle type</template>
                <template v-slot:header:3>Fuel type</template>
                <template v-slot:column:1="{row}">
                    <Field class="flex-grow" rule="NonEmptyString" v-bind:value="row.name" v-slot="{id}">
                        <Textbox v-bind:id="id" v-model:value="row.name" placeholder="Minivan X9876"/>
                    </Field>
                </template>
                <template v-slot:column:2="{row}">
                    <Field class="flex-grow" rule="NotNull" v-bind:value="row.vehicle" v-slot="{id}">
                        <Dropdown v-bind:id="id" v-bind:items="vehicles" v-model:selected="row.vehicle"/>
                    </Field>
                </template>
                <template v-slot:column:3="{row}">
                    <Field class="flex-grow" rule="NotNull" v-bind:value="row.fuel" v-slot="{id}">
                        <Dropdown v-bind:disabled="row.vehicle === null" v-bind:id="id" v-bind:items="fuels(row.vehicle ?? '')" v-model:selected="row.fuel"/>
                    </Field>
                </template>
                <template v-slot:column:4="{index}">
                    <div class="flex flex-gap-2">
                        <a aria-label="Delete" class="i-material-symbols:delete-outline-rounded link text-6" v-bind:class="rows.length === 1 ? 'invisible' : 'visible'" v-on:click="remove(index)"/>
                        <a aria-label="Add" class="i-fa6-solid:plus link text-6" v-bind:class="index === rows.length - 1 ? 'visible' : 'invisible'" v-on:click="add()"/>
                    </div>
                </template>
            </Table>
            <div class="flex flex-gap-6 flex-justify-center m-t-6">
                <Button role="primary" type="button" v-bind:disabled="templates === undefined" v-on:click="validate(true) && next()">Continue</Button>
                <a class="link" v-on:click="skip">Skip</a>
            </div>
        </template>
    </Validation>
</template>
