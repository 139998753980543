/**
 * Filters the template.
 *
 * @param templates the list of templates to filter.
 * @param filter the key and value of the tag to filter.
 * @returns the matching template identifier or @{code null} if no template matches the filter.
 */
export function findTemplate(templates: Array<{id: string, tags: string[]}>, filter: Record<string, string>): string | null
{
    const f = Object.entries(filter);
    const {id} = templates.find(({tags}) => f.every(([k, v]) => tags.includes(`${k}:${v}`))) ?? {id: null};
    return id;
}

/**
 * Filters the templates.
 *
 * @param templates the list of templates to filter.
 * @param filter the key and value of the tag to filter.
 * @param tag the key of the tag for which to return the values.
 * @returns the set of values for the given tag.
 */
export function filterTemplates(templates: Array<{tags: string[]}>, filter: Record<string, string>, tag: string): [string, string][]
{
    const f = Object.entries(filter);
    const re = new RegExp(`^${tag}:(.+)$`);
    return Array.from(new Set(templates.filter(({tags}) => f.every(([k, v]) => tags.includes(`${k}:${v}`))).flatMap(({tags}) => tags.map((tag) => tag.match(re)).flatMap((match) => match === null ? [] : [match[1]])))).map<[string, string]>((name) => [name, name]);
}