<script setup lang="ts">
import Button from "../../components/Button.vue";
import IconList from "../../components/IconList.vue";
import {useGHG} from "../GHG";
import {useProgressBar} from "../../Wizard";

const ghg = useGHG();
const page = "checklist";

const next = () => ghg.value.next(page);
useProgressBar(() => ghg.value.progress(page));
</script>
<template>
    <h1>Checklist before your start</h1>
    <p>To use the GHG assistant, please prepare the following information:</p>
    <IconList icon="i-ic:baseline-check">
        <template v-slot:1>Find out if any of your facilities use boilers, furnaces, ovens, or emergency electricity generators. If yes, determine the fuel type used by each.</template>
        <template v-slot:2>Find out if your organization owns any vehicles used for business purposes. If yes, determine the fuel type used by each.</template>
        <template v-slot:3>Find out if any of of your facilities cause fugitive emissions from air conditioning, refrigeration systems or fire extinguishers. If yes, determine the fuel type used by each.</template>
        <template v-slot:4>Find out if any of of your facilities have newly planted trees within the reporting period that are able to reach at least 5 meters in height. If yes, determine how many.</template>
        <template v-slot:5>Find out if any of of your facilities purchase electricity or gas from third party providers such as an electric or gas company. If yes, determine the utility providers.</template>
    </IconList>
    <div class="flex flex-justify-center m-t-6">
        <Button role="primary" type="button" v-on:click="next()">Continue</Button>
    </div>
</template>
