<script setup lang="ts">
import {computed} from "vue";

interface Props
{
    icon: `i-${string}:${string}`;
}
defineProps<Props>();
const slots = defineSlots();
const names = computed(() => Object.keys(slots));
</script>
<template>
    <div class="flex flex-col flex-gap-6">
        <div class="grid grid-cols-[min-content_1fr] grid-gap-3" v-bind:key="index" v-for="(name, index) of names">
            <div class="color-green h-6 w-6" v-bind:class="icon"/>
            <div>
                <slot v-bind:name="name"/>
            </div>
        </div>
    </div>
</template>
