import ChartCard from "./components/ChartCard.vue";
import type {Component} from "vue";
import type {ComponentProps} from "vue-component-type-helpers";
import ImageCard from "./components/ImageCard.vue";
import ParagraphCard from "./components/ParagraphCard.vue";
import QuoteCard from "./components/QuoteCard.vue";
import StatisticsCard from "./components/StatisticsCard.vue";
import TitleCard from "./components/TitleCard.vue";
import VideoCard from "./components/VideoCard.vue";

export const components: Record<string, Component> =
{
    ChartCard,
    ImageCard,
    ParagraphCard,
    QuoteCard,
    StatisticsCard,
    TitleCard,
    VideoCard
};

export type CardComponent<K extends keyof typeof components, T extends Component> =
{
    card: K,
    props?: ComponentProps<T>,
    slot?: any,
    value: ComponentProps<T>["value"]
};

export type Card =
    CardComponent<"ChartCard", typeof ChartCard> |
    CardComponent<"ImageCard", typeof ImageCard> |
    CardComponent<"ParagraphCard", typeof ParagraphCard> |
    CardComponent<"QuoteCard", typeof QuoteCard> |
    CardComponent<"StatisticsCard", typeof StatisticsCard> |
    CardComponent<"TitleCard", typeof TitleCard> |
    CardComponent<"VideoCard", typeof VideoCard>
