<script lang="ts" setup>
import {computed} from "vue";

type Props =
{
    disabled?: boolean,
    label?: string,
    name?: string,
    radio?: boolean,
    value?: boolean
};
const emit = defineEmits<{(e: "update:value", value: boolean): void}>();
const props = withDefaults(defineProps<Props>(),
{
    disabled: false,
    label: "",
    radio: false,
    value: false
});
const getChecked = () => props.value;
const setChecked = (value: boolean) => emit("update:value", value);
const checked = computed<boolean>({get: getChecked, set: setChecked});
const id = `id_${Math.random().toString(36).substring(2)}`;
const type = computed(() => props.radio ? "radio" : "checkbox");
</script>
<template>
    <div class="flex flex-items-center color-gray hover-color-black max-w-100%" v-bind:class="[disabled ? 'color-middlegray' : 'cursor-pointer']">
        <input class="appearance-none b-1 b-color-current b-solid before-content before-color-green before-scale-0 before-transition-transform box-border color-current cursor-inherit disabled-before-color-middlegray flex flex-items-center flex-justify-center flex-self-start h-6 m-0 outline-0 w-6" v-bind:class="radio ? 'b-rd-4 before-checked-scale-80% before-i-fa6-solid:circle text-4' : 'b-rd-1 before-checked-scale-100% before-i-fa6-solid:check text-5'" v-bind:disabled="disabled" v-bind:id="id" v-bind:name="name" v-bind:type="type" v-model="checked"/>
        <label class="cursor-inherit flex flex-items-center h-100% not-empty-p-l-2" v-bind:for="id">
            <slot>{{label}}</slot>
        </label>
    </div>
</template>