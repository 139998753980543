<script lang="ts">
import Button from "../components/Button.vue";
import {ReportMode} from "./ReportMode";
import {ref} from "vue";
import {useRouter} from "vue-router";

export const disabled = ref(false);
export const working = ref(false);
export const saveFunction = ref<() => void>(() => {});
</script>
<script lang="ts" setup>
interface Props
{
    mode: ReportMode.Edit | ReportMode.View;
    projectId: string;
    reportId: string;
}
const props = withDefaults(defineProps<Props>(), {});
const router = useRouter();
const edit = () => router.push(`/project/${props.projectId}/reports/${props.reportId}/`);
const view = () => router.push(`/project/${props.projectId}/reports/${props.reportId}/view/`);
const print = () => window.print();
const save = () => saveFunction.value();
</script>
<template>
    <div class="flex flex-gap-4">
        <Button fitted role="secondary" type="button" v-on:click="edit" v-if="mode == ReportMode.View">Edit</Button>
        <Button fitted role="secondary" type="button" v-on:click="view" v-if="mode == ReportMode.Edit">Preview</Button>
        <Button fitted role="primary" type="button" v-bind:disabled="disabled" v-bind:loading="working" v-on:click="save" v-if="mode == ReportMode.Edit">Save</Button>
        <Button fitted role="primary" type="button" v-bind:disabled="disabled" v-on:click="print" v-if="mode == ReportMode.View">Export as PDF</Button>
    </div>
</template>