import {type Ref, ref, watchEffect} from "vue";
import FormulaRunnerWorker from "./FormulaRunnerWorker?worker&inline";
import type {FormulaRunnerWorkerModule} from "./FormulaRunnerWorker";
import {invoke} from "./WorkerModule";

export function execute(formula: string, variables: Record<string, {unit?: string; value: number | string}>)
{
    return invoke<FormulaRunnerWorkerModule, "execute">(new FormulaRunnerWorker(), "execute", formula, variables);
}

export function useFormulaRunner(formula: Ref<string>, variables: Ref<Record<string, {unit?: string; value: number | string}>>)
{
    const error = ref<string>();
    const result = ref<ReturnType<FormulaRunnerWorkerModule["execute"]>>();
    const run = async () =>
    {
        try
        {
            result.value = await execute(formula.value, variables.value);
            error.value = undefined;
        }
        catch(e)
        {
            error.value = `${e instanceof Error ? e.message : e}`;
            result.value = undefined;
        }
    };
    watchEffect(run);
    return {error, result};
}