import {type Ref, ref, watch} from "vue";

interface VideoMetadata
{
    aspectRatio: number;
    title: string;
}
export const videoMetadata = ref<Record<string, VideoMetadata>>({});

export const useVideoMetadata = (urls: Ref<string[]>) =>
{
    const resolveMetadata = async (id: string): Promise<[string, VideoMetadata]> =>
    {
        const response = await fetch(`https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=${id}&format=json`);
        if(response.ok)
        {
            const json = await response.json() as {height: number; title: string; width: number} | undefined;
            const {height, title, width} = json ?? {height: 9, title: "Video has not title", width: 16};
            return [id, {aspectRatio: width / height, title}];
        }
        else
        {
            return [id, {aspectRatio: 16 / 9, title: "Video does not exist"}];
        }
    };
    const updateMetadata = async () =>
    {
        const ids = Object.keys(videoMetadata.value);
        const unresolved = urls.value.filter((id) => !ids.includes(id));
        videoMetadata.value = {...videoMetadata.value, ...Object.fromEntries(unresolved.map((id) => [id, {aspectRatio: 16 / 9, title: "Loading..."}]))};

        const promises = unresolved.map((id) => resolveMetadata(id));
        const resolved = await Promise.all(promises);
        videoMetadata.value = {...videoMetadata.value, ...Object.fromEntries(resolved)};
    };
    watch(urls, async () => await updateMetadata(), {deep: true, immediate: true});
};