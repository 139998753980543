<script lang="ts" setup>
interface Emit
{
    (event: "update:selected", value: boolean): void;
}
interface Props
{
    icon?: string;
    selected?: boolean;
}
const props = withDefaults(defineProps<Props>(),
{
    selected: false
});
const emit = defineEmits<Emit>();
const toggle = () => emit("update:selected", !props.selected);
</script>
<template>
    <div class="b-verylightgray b-rd-1.5 b-solid box-border cursor-pointer h-60 grid grid-gap-1.5 grid-justify-center grid-rows-[1fr_1fr_1fr] hover:shadow-lg relative shadow w-60" v-bind:class="props.selected ? 'b-3 b-green p-y-[calc(0.75em_-_2px)] p-x-[calc(1.5em_-_2px)]' : 'b-1 p-y-3 p-x-6'" role="option" v-on:click="toggle">
        <div class="font-500 justify-self-center self-center text-green text-8" v-bind:class="icon" v-if="icon"/>
        <div class="font-500 self-center text-6 text-center">
            <slot name="title"/>
        </div>
        <div class="text-center text-gray">
            <slot name="description"/>
        </div>
    </div>
</template>