<script lang="ts" setup>
import {ReportMode} from "../ReportMode";
import {computed} from "vue";

interface Emits
{
    (event: "add"): void;
    (event: "remove"): void;
}
interface Props
{
    index?: number
    mode: ReportMode;
    value?: unknown[];
}
const emit = defineEmits<Emits>();
const props = withDefaults(defineProps<Props>(),
{
    index: 0,
    value: () => []
});

const add = () => emit("add");
const remove = () => emit("remove");

const addable = computed(() => props.value.length === 0 ? false : props.value[props.index] === null);
const removable = computed(() =>
{
    const {index, value} = props;
    return value.length === 0 ? true : value[index] !== null || index === value.length - 1 && value.every((v) => v === null);
});
</script>
<template>
    <div class="b-1 b-rd-2 b-gray b-dashed relative" v-if="mode === ReportMode.Edit">
        <div class="box-border color-gray cursor-pointer h-100% hover-color-black flex flex-gap-3 flex-items-start flex-justify-start flex-col p-3" role="button" v-if="addable" v-on:click="add()">
            <div class="text-15">+</div>
            <div>
                <slot name="add"/>
            </div>
        </div>
        <slot name="edit"/>
        <slot/>
        <div class="absolute b-rd-50% bg-lightgray cursor-pointer flex flex-items-center flex-justify-center h-6 hover-bg-gray parent right--3 top--3 w-6" v-if="removable" v-on:click="remove()">
            <div class="color-middlegray h-6 i-material-symbols:close-rounded parent-hover-color-lightgray w-6"/>
        </div>
    </div>
    <div class="b-1 b-rd-2 b-gray b-dashed flex flex-col" v-else-if="mode === ReportMode.Preview">
        <slot name="preview"/>
        <slot/>
    </div>
    <template v-else>
        <slot name="view"/>
        <slot/>
    </template>
</template>
