/**
 * CSRF token management.
 */
export default class CSRF
{
    /**
     * Gets the CSRF token.
     *
     * @returns the CSRF token.
     */
    static token()
    {
        let token = localStorage.getItem("X-CSRF-Token");
        if(token === null)
        {
            token = window.btoa(String.fromCharCode(...window.crypto.getRandomValues(new Uint8Array(16)))).replace(/=+$/, "").replaceAll("+", "-").replaceAll("/", "_");
            localStorage.setItem("X-CSRF-Token", token);
        }
        return token;
    }
}