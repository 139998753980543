<script lang="ts" setup>
import Modal from "../components/Modal.vue";
import {onBeforeRouteLeave} from "vue-router";
import {ref} from "vue";

const visible = ref(true);
onBeforeRouteLeave(() =>
{
    visible.value = false;
    return true;
});
</script>
<template>
    <Modal v-bind:closable="false" v-bind:visible="visible">
        <router-view v-slot="{Component}">
            <component v-bind:is="Component"/>
        </router-view>
    </Modal>
</template>