<script lang="ts">
export interface ToolbarButton
{
    action: () => Promise<void> | void;
    disabled?: boolean;
    icon: `i-${string}`;
    label: string;
    wait?: boolean;
}
</script>
<script lang="ts" setup>
import {awaitableSelection} from "../components/Awaitable";
import {ref} from "vue";

interface Props
{
    buttons?: ToolbarButton[];
}
const props = withDefaults(defineProps<Props>(), {buttons: () => []});

const executing = ref(new Set<number>());
const execute = awaitableSelection(executing, async (index: number) =>
{
    const {buttons} = props;
    const {action} = buttons[index];
    await action();
});
</script>
<template>
    <div class="auto-flow-col grid grid-gap-4 text-6" style="grid-auto-columns: min-content">
        <a v-bind:aria-disabled="disabled || executing.has(index)" v-bind:aria-label="label" v-bind:class="executing.has(index) || wait ? 'color-green i-svg-spinners:180-ring-with-bg' : `link ${icon}`" v-bind:key="index" v-on:click="execute(index)" v-for="({disabled, icon, label, wait}, index) of buttons"/>
    </div>
</template>./Awaitable