<script lang="ts" setup>
import {onMounted, ref} from "vue";
import API from "../../API";
import Chart from "../../analyze/Chart.vue";
import type {Measurements} from "../../API";
import ReportCard from "./ReportCard.vue";
import ReportCardItem from "./ReportCardItem.vue";
import {ReportMode} from "../ReportMode";

interface Emits
{
    (event: "remove"): void;
}
interface Props
{
    mode?: ReportMode;
    value?:
    {
        indicatorId: string;
        parameterId?: string;
        projectId: string;
    };
}
const emit = defineEmits<Emits>();
const props = withDefaults(defineProps<Props>(),
{
    mode: ReportMode.View
});
type Indicator =
{
    currency?: string;
    name: string;
    options?: string[];
    project: string;
    unit?: string;
    values: Measurements;
};
const indicator = ref<Indicator | null>(null);
onMounted(async () =>
{
    if(props.value !== undefined)
    {
        const {indicatorId, parameterId, projectId} = props.value;
        indicator.value = await API.fetch("get", "/project/{projectId}/indicator/{indicatorId}/analyze/", {indicatorId, projectId}, {parameterId}, null);
    }
});
const remove = () => emit("remove");
</script>
<template>
    <ReportCard v-bind:mode="mode" v-bind:value="props.value">
        <ReportCardItem v-bind:mode="mode" v-on:remove="remove">
            <div class="grid" v-if="mode === ReportMode.Preview">
                <Chart v-bind:indicator="indicator" v-bind:labels="false" v-bind:measurements="indicator.values" v-if="indicator"/>
            </div>
            <div class="flex flex-col items-center justify-center p-4 border border-solid border-gray-300 min-h-20rem rounded" v-else>
                <div class="color-gray i-svg-spinners:180-ring-with-bg text-6" v-if="indicator === null"/>
                <template v-else>
                    <div class="text-lg font-bold">{{indicator.project}}</div>
                    <div class="text-sm">{{indicator.name}}</div>
                    <div class="w-100%">
                        <Chart v-bind:indicator="indicator" v-bind:measurements="indicator.values"/>
                    </div>
                </template>
            </div>
        </ReportCardItem>
    </ReportCard>
</template>
