<script lang="ts" setup>
import {onBeforeUnmount, onMounted, ref} from "vue";

const navbar = ref(false);
const hide = () => navbar.value = false;
const show = () => navbar.value = true;
const toggle = () => navbar.value = !navbar.value;

const id = `id_${Math.random().toString(36).substring(2)}`;
const dismiss = (event: MouseEvent) =>
{
    if(event.target)
    {
        const target = event.target as HTMLElement;
        if(target.closest(`[data-id='${id}']`) === null)
        {
            navbar.value = false;
        }
    }
};

onMounted(() => window.addEventListener("click", dismiss));
onBeforeUnmount(() => window.removeEventListener("click", dismiss));
</script>
<template>
    <div class="grid grid-cols-0-1-0 sm-grid-cols-3-10-3 grid-rows-[2rem_min-content_minmax(10em,1fr)] sm-grid-rows-[0_min-content_1fr] sm-print-grid-cols-[0_1fr_0] transition transition-property-[grid-template-columns,grid-template-rows]">
        <div class="bg-white bg-op-90 grid-col-2 grid-row-1 sticky flex shadow top-0 z-1 overflow-hidden print-hidden" v-bind:data-id="id">
            <slot name="mobile" v-bind="{hide, show, toggle}"/>
        </div>
        <div class="box-border bg-white bg-op-95 grid grid-rows-[1fr_min-content] grid-col-1 grid-row-start-2 grid-row-end-4 h-[min-content] min-h-[calc(100vh-2rem)] print-hidden sticky top-8 sm-shadow-none sm-transform-scale-100 sm-top-0 sm-w-100% shadow transition-transform transition-property-[transform,width] transform-origin-tl z-1" v-bind:class="navbar ? 'transform-scale-100 w-50vw' : 'transform-scale-0 w-0'" v-bind:data-id="id">
            <div class="flex flex-col flex-items-start truncate">
                <slot name="logo" v-bind="{hide, show, toggle}"/>
            </div>
            <div class="flex flex-gap-4 flex-items-center grid-self-end m-4 sm-hidden print-hidden">
                <slot name="profile"/>
            </div>
        </div>
        <div class="grid-col-2 grid-row-2 p-4 flex flex-items-start flex-justify-between print-hidden">
            <slot name="toolbar"/>
        </div>
        <div class="grid-col-3 grid-row-2 hidden flex-gap-4 flex-items-center flex-justify-center m-4 print-hidden sm-flex">
            <slot name="profile"/>
        </div>
        <div class="grid-col-2 grid-row-3 m-4 m-t-0 print-m-0">
            <slot/>
        </div>
        <div class="grid-col-3 grid-row-3 h-[max-content] hidden sm-block sticky top-4">
            <div class="m-4 m-t-0 h-[max-content]">
                <slot name="sidebar"/>
            </div>
        </div>
    </div>
</template>