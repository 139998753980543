<script lang="ts" setup>
import {inject, onMounted, onUnmounted, provide, ref, watch} from "vue";

interface Props
{
    validator?: (value: any) => Promise<string[]>;
    value?: any;
}
const props = withDefaults(defineProps<Props>(),
{
    validator: () => Promise.resolve([])
});

interface Field
{
    errors: () => Promise<string[]>;
    indicate: () => Promise<void>;
    validate: (indicating?: boolean) => Promise<boolean>;
}
const context =
{
    add: (field: Field) => fields.add(field),
    remove: (field: Field) => fields.delete(field),
    reset: () => reset()
};
const fields: Set<Field> = new Set();
const parent = inject<typeof context | null>("AsyncValidation.context", null);
provide("AsyncValidation.context", context);

const error = ref<string | null>(null);
const errors = async () =>
{
    const {validator} = props;
    const parent = validator(props.value);
    const children = Array.from(fields).map(({errors}) => errors());
    const messages = await Promise.all([parent, ...children]);
    return messages.flat();
};
const indicate = async () =>
{
    for(const {indicate} of fields)
    {
        indicate();
    }
    const messages = await errors();
    error.value = messages.length === 0 ? null : messages.join();
};
const reset = () =>
{
    if(parent !== null)
    {
        parent.reset();
    }
    error.value = null;
};

const validate = async (indicating: boolean = false) =>
{
    const messages = await errors();
    const valid = messages.length === 0;
    if(indicating)
    {
        indicate();
    }
    if(valid)
    {
        reset();
    }
    return valid;
};
watch(() => props.value, () => validate());

const field: Field = {errors, indicate, validate};
onMounted(() => parent?.add(field));
onUnmounted(() => parent?.remove(field));
defineExpose({error, validate});
</script>
<template>
    <slot v-bind:error="error" v-bind:validate="validate"/>
</template>
