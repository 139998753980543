<script lang="ts" setup>
import {Frequencies, Types} from "../indicator/Indicator.vue";
import type {IndicatorFormula, IndicatorFrequency, IndicatorType, PathRequestBody, Unit} from "../API";
import {computed, onMounted, ref} from "vue";
import API from "../API";
import AsyncValidation from "../components/AsyncValidation.vue";
import Button from "../components/Button.vue";
import type {ComponentExposed} from "vue-component-type-helpers";
import Currencies from "../indicator/Currencies.json";
import Dropdown from "../components/Dropdown.vue";
import Field from "../components/Field.vue";
import Formula from "../indicator/components/Formula.vue";
import IndicatorUnit from "../indicator/components/IndicatorUnit.vue";
import MultipleDropdown from "../components/MultipleDropdown.vue";
import Multitext from "../components/Multitext.vue";
import SDGs from "../indicator/SDGs.json";
import TagsDropdown from "../components/TagsDropdown.vue";
import Textarea from "../components/Textarea.vue";
import Textbox from "../components/Textbox.vue";
import Validation from "../components/Validation.vue";
import {useAutosave} from "../components/Autosave";
import {useBreadcrumb} from "../Breadcrumb";
import {useRouter} from "vue-router";

interface Props
{
    templateId: string;
}
const props = withDefaults(defineProps<Props>(), {});

interface Template
{
    currency?: string;
    description?: string;
    formula?: IndicatorFormula;
    frequency?: IndicatorFrequency;
    measurements?: number;
    name?: string;
    options?: string[],
    sdgs?: number[];
    tags?: string[];
    type?: IndicatorType;
    unit?: Unit;
}
const allTags = ref<string[] | null>(null);
const template = ref<Template | null>(null);
onMounted(async () =>
{
    const {templateId} = props;
    template.value = await API.fetch("get", "/template/{templateId}/", {templateId}, {}, null) ?? {};
});

onMounted(async () =>
{
    const tags = await API.fetch("get", "/template/tags/", {}, {}, null);
    allTags.value = tags.sort();
});

const entity = computed((): PathRequestBody<"/template/{templateId}/", "patch"> =>
{
    const {currency, description, formula, frequency, name, options, sdgs, tags, type, unit} = template.value!;
    switch(type)
    {
        case "formula":
        {
            return {description: description!, formula: formula!, frequency: frequency!, name: name!, sdgs, tags: tags!, type};
        }
        case "number":
        case "text":
        {
            return {description: description!, frequency: frequency!, name: name!, sdgs, tags: tags!, type};
        }
        case "multiple-choice":
        case "single-choice":
        {
            return {description: description!, frequency: frequency!, name: name!, options: options!, sdgs, tags: tags!, type};
        }
        case "monetary-value":
        {
            return {currency: currency!, description: description!, frequency: frequency!, name: name!, sdgs, tags: tags!, type};
        }
        case "unit":
        {
            return {description: description!, frequency: frequency!, name: name!, sdgs, tags: tags!, type, unit: unit!};
        }
        default:
        {
            throw new Error("illegal template type");
        }
    }
});

const getName = () => template.value?.name ?? "";
const setName = (value: string) =>
{
    template.value!.name = value;
    autosave();
};
const name = computed({get: getName, set: setName});

const getDescription = () => template.value?.description ?? "";
const setDescription = (value: string) =>
{
    template.value!.description = value;
    autosave();
};
const description = computed({get: getDescription, set: setDescription});

const getFrequency = () => template.value?.frequency ?? null;
const setFrequency = (value: IndicatorFrequency | null) =>
{
    template.value!.frequency = value ?? undefined;
    autosave();
};
const frequency = computed({get: getFrequency, set: setFrequency});

const getSDGs = () => template.value?.sdgs?.map((v) => v - 1) ?? [];
const setSDGs = (value: number[]) =>
{
    template.value!.sdgs = value.map((v) => v + 1);
    autosave();
};
const sdgs = computed({get: getSDGs, set: setSDGs});

const getTags = () => template.value?.tags ?? [];
const setTags = (value: string[]) =>
{
    template.value!.tags = value;
    autosave();
};
const tags = computed({get: getTags, set: setTags});

const getType = () => template.value?.type ?? null;
const setType = (value: IndicatorType | null) =>
{
    template.value!.type = value ?? undefined;
    autosave();
};
const type = computed({get: getType, set: setType});

const getCurrency = () => template.value?.currency ?? null;
const setCurrency = (value: string | null) =>
{
    template.value!.currency = value ?? undefined;
    autosave();
};
const currency = computed({get: getCurrency, set: setCurrency});

const getOptions = () => template.value?.options ?? ["", ""];
const setOptions = (value: string[]) =>
{
    template.value!.options = value;
    autosave();
};
const options = computed({get: getOptions, set: setOptions});

const getUnit = () => template.value?.unit ?? null;
const setUnit = (unit: Unit | null) =>
{
    template.value!.unit = unit === null ? undefined : unit;
    autosave();
};
const unit = computed({get: getUnit, set: setUnit});

const getFormula = () => template.value?.formula ?? {expression: "", parameters: {}, unit: ""};
const setFormula = async (value: IndicatorFormula) =>
{
    template.value!.formula = value;
    autosave();
};

const formula = computed<IndicatorFormula>({get: getFormula, set: setFormula});

const asyncValidation = ref<ComponentExposed<typeof AsyncValidation>>();
const validation = ref<ComponentExposed<typeof Validation>>();

const {autosave, save, saving} = useAutosave("patch", "/template/{templateId}/", async (fetch) =>
{
    if(validation.value!.validate() && await asyncValidation.value!.validate())
    {
        const {templateId} = props;
        await fetch({templateId}, {}, entity.value);
    }
});

const router = useRouter();
const submit = async () =>
{
    if(validation.value!.validate(true) && await asyncValidation.value!.validate(true))
    {
        await save();
        await router.push("/template/");
    }
};
const readonly = computed(() => template.value === null ? false : template.value.measurements === undefined ? false : template.value.measurements > 0);

useBreadcrumb({templateId: () => template.value?.name});
</script>
<template>
    <h1>Define indicator template</h1>
    <AsyncValidation ref="asyncValidation">
        <Validation ref="validation">
            <form class="flex flex-col flex-gap-5" v-on:submit.prevent="submit">
                <Field label="Indicator name" rule="NonEmptyString" v-bind:value="name" v-slot="{id}">
                    <Textbox v-bind:disabled="template === null" v-bind:id="id" v-model:value="name"/>
                </Field>
                <Field label="Indicator description" rule="NonEmptyString" v-bind:value="description" v-slot="{id}">
                    <Textarea class="b-b b-b-color-inherit b-b-solid color-inherit p-y-2.5" v-bind:disabled="template === null" v-bind:id="id" v-model:value="description"/>
                </Field>
                <Field label="Tags" rule="Tags" v-bind:value="tags" v-slot="{id}">
                    <TagsDropdown v-bind:disabled="template === null" v-bind:id="id" v-bind:tags="allTags ?? tags" v-model:values="tags"/>
                </Field>
                <Field label="Related SDGs" v-bind:value="sdgs" v-slot="{id}">
                    <MultipleDropdown v-bind:disabled="template === null" v-bind:id="id" v-bind:items="SDGs.map((v, n) => `SDG ${n + 1}: ${v}`)" v-model:selected="sdgs"/>
                </Field>
                <Field label="Frequency of measurement" rule="NotNull" v-bind:value="frequency" v-slot="{id}">
                    <Dropdown v-bind:disabled="template === null" v-bind:id="id" v-bind:items="Frequencies" v-bind:readonly="readonly" v-model:selected="frequency"/>
                </Field>
                <Field label="Measurement type" rule="NotNull" v-bind:value="type" v-slot="{id}">
                    <Dropdown v-bind:disabled="template === null" v-bind:id="id" v-bind:items="Types" v-bind:readonly="readonly" v-model:selected="type"/>
                </Field>
                <Field label="Options" rule="TwoOrMoreStrings" v-bind:value="options" v-if="type === 'multiple-choice' || type === 'single-choice'" v-slot="{id}">
                    <Multitext rule="NonEmptyString" v-bind:id="id" v-bind:maximum="readonly ? options.length : 50" v-bind:minimum="readonly ? options.length : 2" v-bind:readonly="readonly" v-model:values="options"/>
                </Field>
                <Field label="Currency" rule="NotNull" v-bind:value="currency" v-if="type === 'monetary-value'" v-slot="{id}">
                    <Dropdown v-bind:id="id" v-bind:items="Currencies.map((v) => [v, v])" v-bind:readonly="readonly" v-model:selected="currency"/>
                </Field>
                <IndicatorUnit v-bind:disabled="template === null" v-bind:optional="false" v-bind:readonly="readonly" v-model:unit="unit" v-if="type === 'unit'"/>
                <Formula v-bind:disabled="template === null" v-bind:readonly="readonly" v-model:formula="formula" v-if="type === 'formula'"/>
                <Button class="flex-self-center" role="primary" type="submit" v-bind:disabled="template === null" v-bind:loading="saving">Save</Button>
            </form>
        </Validation>
    </AsyncValidation>
</template>