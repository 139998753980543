import KeepAlive from "../KeepAlive.vue";
import Measurement from "./Measurement.vue";
import type {RouteRecordRaw} from "vue-router";

export const MeasurementRoute: RouteRecordRaw =
{
    children:
    [
        {
            component: Measurement,
            path: "",
            props: ({params: {indicatorId, projectId}}) => ({date: "latest", indicatorId, projectId})
        },
        {
            component: Measurement,
            path: ":date([0-9]{4}-[0-9]{2}-[0-9]{2})/",
            props: ({params: {date, indicatorId, projectId}}) => ({date, indicatorId, projectId})
        }
    ],
    component: KeepAlive,
    path: "measurement/"
};