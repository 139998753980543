<script lang="ts" setup>
import {useSlots} from "vue";

interface Props
{
    loading?: boolean;
}
const slots = useSlots();
withDefaults(defineProps<Props>(), {loading: false});
</script>
<template>
    <div class="b-1 b-verylightgray b-rd-1.5 b-solid cursor-pointer h-40 grid grid-rows-[0fr_1fr] hover:shadow-lg relative shadow w-60" role="button">
        <div class="bg-green box-border b-rd-t-2 font-600 justify-self-top p-x-2 p-y-1 text-center text-white" v-if="slots.header">
            <slot name="header"/>
        </div>
        <div class="box-border grid grid-items-center grid-justify-items-center grid-row-2 p-x-3 p-y-6">
            <slot/>
        </div>
        <div class="absolute b-rd-2 bg-white bg-opacity-75 flex flex-items-center flex-justify-center h-100% left-0 top-0 w-100%" v-if="loading">
            <div class="color-green i-svg-spinners:180-ring-with-bg text-6"/>
        </div>
    </div>
</template>