<script setup lang="ts">
// @ts-ignore
import * as mhchem from "katex/dist/contrib/mhchem";
import {ref, watchEffect} from "vue";
import TexWorker from "./TexWorker?worker&inline";
import type {TexWorkerModule} from "./TexWorker";
import {invoke} from "./WorkerModule";
import katex from "katex";

mhchem;

interface Props
{
    expression?: string | null;
    type: "expression" | "unit" | "variable";
}
const props = withDefaults(defineProps<Props>(),
{
    expression: null
});
const html = ref<string | null>(null);
const render = (tex: string, block: boolean = false) =>
{
    const ELEMENTS = /^(?:(?:CO2e|Ac|Ag|Al|Am|Ar|As|At|Au|Ba|Be|Bh|Bi|Bk|Br|B|Ca|Cd|Ce|Cf|Cl|Cm|Cn|Co|Cr|Cs|Cu|C|Db|Ds|Dy|Er|Es|Eu|Fe|Fl|Fm|Fr|F|Ga|Gd|Ge|He|Hf|Hg|Ho|Hs|H|In|Ir|I|Kr|K|La|Li|Lr|Lu|Lv|Mc|Md|Mg|Mn|Mo|Mt|Na|Nb|Nd|Ne|Nh|Ni|No|Np|N|Og|Os|O|Pa|Pb|Pd|Pm|Po|Pr|Pt|Pu|P|Ra|Rb|Re|Rf|Rg|Rh|Rn|Ru|Sb|Sc|Se|Sg|Si|Sm|Sn|Sr|S|Ta|Tb|Tc|Te|Th|Ti|Tl|Tm|Ts|Uue|U|V|W|Xe|Yb|Y|Zn|Zr)\d*)+$/;
    const units = tex.replace(/(?:\\mathrm\{([^}]+)\})/g, (_, unit) => (unit as string).split(/\\?_/).map((s) => `\\mathrm{${s}}`).join("\\,"));
    const chemtex = units.replace(/(?:\\mathrm\{([^}]+)\})|((?:[A-Z][a-z]?[0-9]*)+)/g, (match, a, b) => ELEMENTS.test((a ?? b) as string) ? `\\ce{${a ?? b}}` : match);
    return katex.renderToString(chemtex, {displayMode: block, output: "mathml", throwOnError: false});
};
const format = async (expression: string, type: "expression" | "unit" | "variable") =>
{
    switch(type)
    {
        case "expression":
        {
            return render(await invoke<TexWorkerModule, "toTex">(new TexWorker(), "toTex", expression), true);
        }
        case "unit":
        {
            return render(expression.replace(/[A-Za-z0-9_]+/g, (match) => `\\mathrm{${match}}`).replace(/ +/, "~"));
        }
        case "variable":
        {
            return render(expression.replace(/[A-Za-z0-9]+/g, (match) => `\\mathit{${match}}`).replace(/ +/, "~"));
        }
    }
};
watchEffect(async () =>
{
    const {expression, type} = props;
    if(expression === null)
    {
        html.value = null;
    }
    else
    {
        html.value = await format(expression, type);
    }
});
</script>
<style scoped>
span:deep(math)
{
    display: math inline;
}
</style>
<template>
    <span v-html="html" v-if="html"/>
</template>