import Checklist from "./pages/Checklist.vue";
import Fugitive from "./pages/Fugitive.vue";
import GHG from "./GHG.vue";
import Indirect from "./pages/Indirect.vue";
import Mobile from "./pages/Mobile.vue";
import Removal from "./pages/Removal.vue";
import type {RouteRecordRaw} from "vue-router";
import Sources from "./pages/Sources.vue";
import Stationary from "./pages/Stationary.vue";
import WizardLayout from "../layouts/WizardLayout.vue";

export const AssistantRoute: RouteRecordRaw =
{
    children:
    [
        {
            component: Checklist,
            path: ""
        },
        {
            component: Fugitive,
            path: "fugitive/"
        },
        {
            component: Indirect,
            path: "indirect/"
        },
        {
            component: Mobile,
            path: "mobile/"
        },
        {
            component: Removal,
            path: "removal/"
        },
        {
            component: Sources,
            path: "sources/"
        },
        {
            component: Stationary,
            path: "stationary/"
        }
    ],
    component: GHG,
    meta:
    {
        layout: WizardLayout
    },
    path: "assistant/",
    props: ({params: {projectId}}) => ({projectId})
};