<script generic="T" lang="ts" setup>
import Validation from "./Validation.vue";
import {useRules} from "./Validator";

interface Props
{
    id?: string;
    label?: string;
    rule?: string;
    validator?: (value: T) => string[];
    value?: T;
}
const props = withDefaults(defineProps<Props>(),
{
    label: "",
    validator: () => []
});
defineOptions({inheritAttrs: false});

const random = `id_${Math.random().toString(36).substring(2)}`;

const validation = (value: any) =>
{
    const {rule, validator} = props;
    const validate = useRules(...rule === undefined ? [] : [rule]);
    const messages1 = validate(value);
    const messages2 = validator(value);
    return [...messages1, ...messages2];
};
</script>
<template>
    <Validation v-bind:validator="validation" v-bind:value="value" v-slot="{error}">
        <div class="group focus-within-color-black hover-color-black transition-color transition-duration-250" role="group" v-bind="$attrs" v-bind:class="[error ? 'color-pink invalid' : 'color-gray valid']">
            <label class="block color-gray font-sans font-600 text-3" v-bind:for="id ?? random" v-if="label">
                <span>{{label}}</span>
                <span v-if="rule"> ∗</span>
            </label>
            <slot v-bind:id="id ?? random"/>
            <div class="grid">
                <div class="font-sans group-[.invalid]-color-pink m-t-1 text-4 overflow-hidden text-ellipsis white-space-nowrap">
                    <slot name="error" v-bind:error="error">{{error ?? "&nbsp;"}}</slot>
                </div>
            </div>
        </div>
    </Validation>
</template>