<script lang="ts" setup>
import type {IndicatorFormula, IndicatorFrequency, IndicatorType, Measurement, Unit} from "../API";
import {awaitable, awaitableWithAbort} from "../components/Awaitable";
import {onMounted, ref, watch} from "vue";
import API from "../API";
import type {Interval} from "luxon";
import Measure from "./Measure.vue";
import {useAutosave} from "../components/Autosave";
import {useBreadcrumb} from "../Breadcrumb";
import {useRouter} from "vue-router";

interface Props
{
    date: string;
    indicatorId: string;
    projectId: string;
}
const props = withDefaults(defineProps<Props>(), {});

interface Indicator
{
    currency?: string;
    description?: string;
    formula?: IndicatorFormula;
    frequency?: IndicatorFrequency;
    measurements?: number;
    name?: string;
    options?: string[],
    type?: IndicatorType;
    unit?: Unit;
}

const dates = ref<string[]>([]);
onMounted(async () =>
{
    const {indicatorId, projectId} = props;
    dates.value = await API.fetch("get", "/project/{projectId}/indicator/{indicatorId}/measurement/", {indicatorId, projectId}, {}, null);
});

const indicator = ref<Indicator | null>(null);
onMounted(async () =>
{
    const {indicatorId, projectId} = props;
    indicator.value = await API.fetch("get", "/project/{projectId}/indicator/{indicatorId}/", {indicatorId, projectId}, {}, null);
});

const interval = ref<Interval | null>(null);
const measurement = ref<Measurement | null>(null);

const refreshing = ref(false);
const {abortable: refresh} = awaitableWithAbort(refreshing, async (abort) =>
{
    const {indicatorId, projectId} = props;
    if(interval.value !== null)
    {
        const date = interval.value.start!.toISODate()!;
        measurement.value = await API.fetch("get", "/project/{projectId}/indicator/{indicatorId}/measurement/{date}/", {date, indicatorId, projectId}, {}, null, abort.signal);
    }
});
watch(interval, async () =>
{
    if(interval.value !== null)
    {
        await refresh();
    }
});

const router = useRouter();
const select = async (selected: Interval) =>
{
    const {indicatorId, projectId} = props;
    const date = selected.start!.toISODate();
    await router.replace(`/project/${projectId}/indicator/${indicatorId}/measurement/${date}/`);
    interval.value = selected;
};

const {autosave, save, saving} = useAutosave("put", "/project/{projectId}/indicator/{indicatorId}/measurement/", async (fetch) =>
{
    const {indicatorId, projectId} = props;
    const date = interval.value!.start!.toISODate()!;
    await fetch({indicatorId, projectId}, {}, {...measurement.value!, date});
    dates.value.push(date);
});

const removing = ref(false);
const remove = awaitable(removing, async () =>
{
    const {indicatorId, projectId} = props;
    const date = interval.value!.start!.toISODate()!;
    await API.fetch("delete", "/project/{projectId}/indicator/{indicatorId}/measurement/{date}/", {date, indicatorId, projectId}, {}, null);
    dates.value = dates.value.filter((d) => d !== date);
    measurement.value = null;
});

const update = (actual: Measurement) =>
{
    measurement.value = actual;
    autosave();
};

useBreadcrumb({indicatorId: () => indicator.value?.name});
</script>
<template>
    <Measure v-bind:actual="measurement" v-bind:date="date" v-bind:dates="dates" v-bind:indicator="indicator" v-bind:refreshing="refreshing" v-bind:removing="removing" v-bind:saving="saving" v-on:remove="remove" v-on:submit="save" v-on:update:actual="update" v-on:update:interval="select"/>
</template>
