<script lang="ts" setup>
import Button from "../components/Button.vue";
import CSRF from "../CSRF";
import {LINKEDIN_CLIENT_ID} from "../Environment";

interface Props
{
    redirect?: string;
}
const props = withDefaults(defineProps<Props>(), {redirect: "/"});

const submit = () =>
{
    const csrf = CSRF.token();
    const redirect = encodeURIComponent(props.redirect);
    const state = `${csrf}.${redirect}`;
    const url = new URL("https://www.linkedin.com/oauth/v2/authorization");
    const params =
    {
        client_id: LINKEDIN_CLIENT_ID,
        redirect_uri: `${window.location.origin}/linkedin/`,
        response_type: "code",
        scope: "r_emailaddress r_liteprofile",
        state
    };
    Object.entries(params).forEach(([key, value]) => url.searchParams.append(key, value));
    window.open(url.toString(), "_self");
};
</script>
<template>
    <Button role="secondary" type="button" v-on:click="submit">
        <span class="i-logos:linkedin-icon text-5 m-r-2"/>
        <span>Sign in with LinkedIn</span>
    </Button>
</template>